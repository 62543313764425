var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: `stats_img__${_vm.xid}` },
    [
      !_vm.manager.states.options.hideLikeDislike
        ? _c(
            "b-button-group",
            { attrs: { size: "sm" } },
            [
              _c(
                "b-button",
                {
                  staticClass: "btn bg-transparent border-0",
                  attrs: { disabled: _vm.isCommentStatsLoading },
                  on: { click: _vm.handleAddLike },
                },
                [
                  _vm.isCommentStatsLoading
                    ? _c("b-spinner", {
                        staticStyle: { "margin-right": "15px" },
                        attrs: { small: "" },
                      })
                    : _c(
                        "div",
                        {
                          staticClass: "d-flex align-items-center",
                          staticStyle: { color: "rgba(6, 38, 62, 0.84)" },
                        },
                        [
                          _c(_vm.getLucideIcon("ThumbsUp"), {
                            tag: "component",
                            attrs: {
                              color: "#5B6164D6",
                              size: 18,
                              "stroke-width": 2.5,
                            },
                          }),
                          _vm.commentStats.likes.length > 0
                            ? _c(
                                "div",
                                {
                                  staticClass: "ml-1",
                                  staticStyle: {
                                    "font-size": "15px",
                                    color: "rgba(6, 38, 62, 0.64)",
                                    "margin-top": "4px",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t" +
                                      _vm._s(_vm.commentStats.likes) +
                                      "\n\t\t\t\t"
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                ],
                1
              ),
              _c(
                "b-button",
                {
                  staticClass: "btn bg-transparent border-0",
                  attrs: { disabled: _vm.isCommentStatsLoading },
                  on: { click: _vm.handleAddDisLike },
                },
                [
                  _vm.isCommentStatsLoading
                    ? _c("b-spinner", {
                        staticStyle: { "margin-right": "15px" },
                        attrs: { small: "" },
                      })
                    : _c(
                        "div",
                        {
                          staticClass: "d-flex align-items-center",
                          staticStyle: { color: "rgba(6, 38, 62, 0.84)" },
                        },
                        [
                          _c(_vm.getLucideIcon("ThumbsDown"), {
                            tag: "component",
                            attrs: {
                              color: "#5B6164D6",
                              size: 18,
                              "stroke-width": 2.5,
                            },
                          }),
                          _vm.commentStats.disLikes.length > 0
                            ? _c(
                                "div",
                                {
                                  staticClass: "ml-1",
                                  staticStyle: {
                                    "font-size": "15px",
                                    color: "rgba(6, 38, 62, 0.64)",
                                    "margin-top": "4px",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t" +
                                      _vm._s(_vm.commentStats.disLikes) +
                                      "\n\t\t\t\t"
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                ],
                1
              ),
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover.right",
                      modifiers: { hover: true, right: true },
                    },
                  ],
                  staticClass: "btn bg-transparent border-0",
                  attrs: {
                    disabled: _vm.isCommentStatsLoading,
                    title: _vm.commentBtnText,
                  },
                  on: { click: _vm.handleLoadCommentList },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "d-flex align-items-center",
                      staticStyle: { color: "rgba(6, 38, 62, 0.84)" },
                    },
                    [
                      _c(_vm.getLucideIcon("MessageCircle"), {
                        tag: "component",
                        attrs: {
                          color: "#5B6164D6",
                          size: 18,
                          "stroke-width": 2.5,
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          )
        : _c(
            "b-button",
            {
              staticClass: "btn bg-transparent border-0",
              attrs: { disabled: _vm.isCommentStatsLoading },
              on: { click: _vm.handleLoadCommentList },
            },
            [
              _c("b-spinner", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isCommentStatsLoading,
                    expression: "isCommentStatsLoading",
                  },
                ],
                staticStyle: { "margin-right": "15px" },
                attrs: { small: "" },
              }),
              _c(
                "div",
                {
                  staticClass: "d-flex align-items-center",
                  staticStyle: { color: "rgba(6, 38, 62, 0.84)" },
                },
                [
                  _c(_vm.getLucideIcon("MessageCircle"), {
                    tag: "component",
                    attrs: {
                      color: "#5B6164D6",
                      size: 18,
                      "stroke-width": 2.5,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }