var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("transition", { attrs: { name: "slide" } }, [
        _vm.isShowRoomOpen
          ? _c(
              "div",
              {
                key: _vm.showRoomToken,
                staticClass: "main-layer--imageshowroom--component-wrapper",
                class: _vm.rendMainLayerClass,
                style: _vm.rendMainLayerStyle,
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "content",
                    class: `content-type--${_vm.manager.states.options.presentationMode}`,
                  },
                  [
                    _c(
                      "MetaDataHeadBar",
                      {
                        ref: "documentViewerModal",
                        on: { close: _vm.handleCloseShowRoomMainModal },
                      },
                      [
                        _vm.hasDocuments
                          ? _c(
                              "template",
                              { slot: "actions" },
                              [
                                !_vm.manager.states.options.hideCropButton
                                  ? _c(
                                      "b-button",
                                      {
                                        staticClass:
                                          "btn bg-transparent border-0 px-0",
                                        staticStyle: {
                                          width: "42px",
                                          "margin-right": "8px",
                                        },
                                        attrs: {
                                          id: "tooltip-target-1675",
                                          size: "sm",
                                        },
                                        on: { click: _vm.handleCropPicture },
                                      },
                                      [
                                        _c(_vm.getLucideIcon("Crop"), {
                                          tag: "component",
                                          attrs: {
                                            color: "#5B6164D6",
                                            size: 18,
                                            "stroke-width": 2.5,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "b-tooltip",
                                  {
                                    attrs: {
                                      target: "tooltip-target-87557",
                                      triggers: "hover",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm.FormMSG(
                                            564,
                                            "Delete this picture"
                                          )
                                        ) +
                                        "\n\t\t\t\t\t\t"
                                    ),
                                  ]
                                ),
                                !_vm.manager.states.options.hideDeleteButton
                                  ? _c(
                                      "b-button",
                                      {
                                        staticClass:
                                          "btn bg-transparent border-0 px-0",
                                        staticStyle: { width: "42px" },
                                        attrs: {
                                          id: "tooltip-target-87557",
                                          disabled: _vm.isImageDeleteingLoading,
                                          size: "sm",
                                        },
                                        on: { click: _vm.handleDeletePicture },
                                      },
                                      [
                                        _vm.isImageDeleteingLoading
                                          ? _c("b-spinner", {
                                              staticStyle: {
                                                width: "18px",
                                                height: "18px",
                                              },
                                              attrs: { small: "" },
                                            })
                                          : _c(_vm.getLucideIcon("Trash2"), {
                                              tag: "component",
                                              attrs: {
                                                color: "#EA4E2C",
                                                size: 18,
                                                "stroke-width": 2.5,
                                              },
                                            }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "b-tooltip",
                                  {
                                    attrs: {
                                      target: "tooltip-target-1675",
                                      triggers: "hover",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm.FormMSG(
                                            8976556,
                                            "Crop this picture"
                                          )
                                        ) +
                                        "\n\t\t\t\t\t\t"
                                    ),
                                  ]
                                ),
                                !_vm.isStatsButtonHide
                                  ? _c("StatsButtons", {
                                      attrs: { manager: _vm.manager },
                                    })
                                  : _vm._e(),
                                _c("SignDocumentTool", {
                                  attrs: { manager: _vm.manager },
                                  on: {
                                    "sign-document-tool:watingForOodrive":
                                      _vm.onWatingForOodrive,
                                    "sign-document-tool:closeViewer":
                                      _vm.onViewerClosed,
                                    "sign-document-tool:documentUpdateStatus":
                                      _vm.onOodriveSignContractStatusUpdated,
                                  },
                                }),
                                _c(
                                  "b-button",
                                  {
                                    directives: [
                                      {
                                        name: "b-tooltip",
                                        rawName: "v-b-tooltip.hover.left",
                                        modifiers: { hover: true, left: true },
                                      },
                                    ],
                                    staticClass: "btn bg-transparent border-0",
                                    attrs: {
                                      size: "sm",
                                      disabled: _vm.isCurrFileDownloading,
                                      title: _vm.FormMSG(6, "Download"),
                                    },
                                    on: { click: _vm.handleCurrDocDownload },
                                  },
                                  [
                                    _vm.isCurrFileDownloading
                                      ? _c("b-spinner", {
                                          attrs: { small: "" },
                                        })
                                      : _vm._e(),
                                    !_vm.isCurrFileDownloading
                                      ? _c(
                                          _vm.getLucideIcon(
                                            _vm.ICONS.DOWNLOAD.name
                                          ),
                                          {
                                            tag: "component",
                                            attrs: {
                                              color: _vm.ICONS.EDIT.color,
                                              size: 20,
                                            },
                                          }
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      2
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "content-body-container",
                        class: {
                          "has-horizontal-style":
                            _vm.manager.states.options.presentationMode !==
                            "image",
                        },
                      },
                      [
                        !_vm.hasDocuments
                          ? _c(
                              "b-alert",
                              {
                                staticStyle: {
                                  position: "absolute",
                                  left: "0",
                                  right: "0",
                                  top: "200px",
                                  margin: "auto",
                                  "max-width": "200px",
                                },
                                attrs: { show: "", variant: "info" },
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t" +
                                    _vm._s(
                                      _vm.FormMSG(7867867, "No element to show")
                                    ) +
                                    "\n\t\t\t\t\t"
                                ),
                              ]
                            )
                          : _vm._e(),
                        _c("CarouselPanel", {
                          key: `carousel_panel_${_vm.showRoomToken}`,
                          attrs: {
                            id: `carousel_panel_${_vm.showRoomToken}`,
                            manager: _vm.manager,
                          },
                          on: { close: _vm.handleCloseShowRoomMainModal },
                        }),
                        _c("ImagesListThumbnails", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.manager.states.isCarouselControlActive,
                              expression:
                                "manager.states.isCarouselControlActive",
                            },
                          ],
                          attrs: { manager: _vm.manager },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("delete-image-modal", {
                  on: {
                    "delete-image-modal:hide": _vm.hideImageDeleteWarning,
                    "delete-image-modal:confirm": _vm.confirmImageDelete,
                  },
                  model: {
                    value: _vm.showDeleteImageWarning,
                    callback: function ($$v) {
                      _vm.showDeleteImageWarning = $$v
                    },
                    expression: "showDeleteImageWarning",
                  },
                }),
                _vm.hasCommentsActive
                  ? _c("CommentPanel", { attrs: { manager: _vm.manager } })
                  : _vm._e(),
                _vm.hasCommentsActive
                  ? _c("PinCommentModal", { attrs: { manager: _vm.manager } })
                  : _vm._e(),
                _vm.isLoading
                  ? _c(
                      "div",
                      {
                        staticClass: "text-center bg-transparent",
                        attrs: { id: "overlaySignDocument" },
                      },
                      [
                        _c(
                          "p",
                          [
                            _c("loading", {
                              attrs: {
                                active: _vm.isLoading,
                                loader: "bars",
                                color: "#00A09C",
                                width: 64,
                              },
                              on: {
                                "update:active": function ($event) {
                                  _vm.isLoading = $event
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "p",
                          {
                            staticClass: "loading-text",
                            attrs: { id: "cancel-label" },
                          },
                          [_vm._v(_vm._s(_vm.FormMSG(122, "Please wait...")))]
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }