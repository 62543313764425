<template>
  <div
    class="markdown-file--component-wrapper"
    :class="{ isMarkdownFileSourceLoading: 'centered_content' }"
  >
		<loading :active.sync="isMarkdownFileSourceLoading" />

    <div v-if="!isMarkdownFileSourceLoading">
      <VueMarkdown v-if="$has(markdownContent)" :source="markdownContent" />
      
      <b-container v-else class="fallback-container" style="padding-top: 200px;">
        <b-alert variant="info" class="push_b_40" show>
          {{ FormMSG(1, "something seem wrong with this file") }}
        </b-alert>
        <b-button block variant="success" @click="downloadFile">
          {{ FormMSG(3, 'Download') }}
        </b-button>
      </b-container>
    </div>
  </div>
</template>

<script>
import mapProps from '@/shared/vuePropsMapper';

import Loading from 'vue-loading-overlay';
import VueMarkdown from 'vue-markdown';

import languageMessages from '@/mixins/languageMessages';

export default {
  name: 'MarkdownDocViewerComponent',
  components: { VueMarkdown },  
  mixins: [languageMessages],
	props: mapProps(['src', 'xid'], {
		type: String,
		required: true,
		default: null
	}),
  data() {
    return {
      markdownContent: null,
      isMarkdownFileSourceLoading: false
    };
  },
  created() {
    this.initMarkdownFileSource()
  },
  methods: {
    async initMarkdownFileSource() {
      this.isMarkdownFileSourceLoading = true;
      const res = await this.$axios.$get(this.src);
      this.isMarkdownFileSourceLoading = false;
      this.markdownContent = res;
    },
  },
}
</script>

<style scoped>
.markdown-file--component-wrapper {
	width: 100%;
	height: 100%;
	position: relative;
}
</style>
