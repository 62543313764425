var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "default-container",
      staticClass: "app",
      class: {
        "no-scroll": _vm.isShowRoomPictureOpen,
        "is-inpwa": _vm.$isPwa(),
      },
    },
    [
      _c(
        "AppHeader",
        {
          key: `${_vm.keyVersion}1`,
          class: { "is-inpwa": _vm.$isPwa() },
          attrs: { fixed: "" },
        },
        [
          _c(
            "b-row",
            { staticClass: "ml-0" },
            [
              _vm.$isPwa()
                ? _c("BackInHistoryBtn", {
                    key: `${_vm.keyVersion}2`,
                    staticClass: "mr-3",
                  })
                : _vm._e(),
              _c("SidebarToggler", {
                key: `${_vm.keyVersion}3`,
                staticClass: "d-lg-none",
                attrs: { display: "sm", mobile: "" },
              }),
              _c(
                "a",
                {
                  staticClass: "navbar-brand",
                  staticStyle: { cursor: "pointer" },
                  attrs: { href: `#/my-dashboard?v=${_vm.version}` },
                },
                [
                  _c("img", {
                    staticClass: "navbar-brand-full",
                    attrs: {
                      src: "img/brand/thegreenshot-brand.png",
                      width: "100",
                      height: "30",
                      alt: "TheGreenShot",
                    },
                  }),
                  _c("img", {
                    staticClass: "navbar-brand-minimized",
                    attrs: {
                      src: "img/brand/tgs-icon.svg",
                      width: "30",
                      height: "30",
                      alt: "TGS",
                    },
                  }),
                ]
              ),
              _c("SidebarToggler", {
                key: `${_vm.keyVersion}4`,
                staticClass: "d-md-down-none",
                attrs: { display: "sm" },
              }),
              _c(
                "b-navbar-nav",
                { staticClass: "d-md-down-none" },
                [_c("HeaderProjectToggler", { key: `${_vm.keyVersion}5` })],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mr-0" },
            [
              _c(
                "b-navbar-nav",
                { staticClass: "d-flex", staticStyle: { gap: "5px" } },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-outline-primary btn-sm d-flex",
                      staticStyle: { gap: "5px" },
                      attrs: { type: "button" },
                      on: { click: _vm.backToMyDashboard },
                    },
                    [
                      _c("home", { attrs: { size: 16 } }),
                      _vm.$screen.width >= 992
                        ? _c("span", [_vm._v(_vm._s(_vm.FormMSG(44, "Home")))])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("HeaderLanguageToggler", { key: `${_vm.keyVersion}6` }),
                  _c(
                    "div",
                    [
                      _vm.$screen.width >= 992
                        ? _c(
                            "b-dropdown",
                            {
                              staticClass: "dropdown--large",
                              attrs: {
                                size: "sm",
                                id: "dropdown-header-help",
                                text: _vm.menuLabel(),
                                variant: "outline-primary",
                                right: "",
                              },
                            },
                            [
                              _c(
                                "b-dropdown-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.openDocumentation()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.FormMSG(19946, "Online guide")
                                      ) +
                                      "\n\t\t\t\t\t\t"
                                  ),
                                ]
                              ),
                              _c(
                                "b-dropdown-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.downloadStartUpGuide()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.FormMSG(
                                          19947,
                                          "Quick Startup PDF Guide"
                                        )
                                      ) +
                                      "\n\t\t\t\t\t\t"
                                  ),
                                ]
                              ),
                              _vm.isFilmSingle
                                ? _c(
                                    "b-dropdown-item",
                                    { on: { click: _vm.showWizard } },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.FormMSG(19948, "View wizard")
                                          ) +
                                          "\n\t\t\t\t\t\t"
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _c(
                            "b-dropdown",
                            {
                              staticClass: "dropdown--large",
                              attrs: {
                                size: "sm",
                                id: "dropdown-header-help",
                                variant: "outline-primary",
                                "toggle-class": "text-decoration-none",
                                "no-caret": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "button-content",
                                  fn: function () {
                                    return [
                                      _c("help-circle", {
                                        attrs: { size: 16 },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            },
                            [
                              _c(
                                "b-dropdown-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.openDocumentation()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.FormMSG(19946, "Online guide")
                                      ) +
                                      "\n\t\t\t\t\t\t"
                                  ),
                                ]
                              ),
                              _c(
                                "b-dropdown-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.downloadStartUpGuide()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.FormMSG(
                                          19947,
                                          "Quick Startup PDF Guide"
                                        )
                                      ) +
                                      "\n\t\t\t\t\t\t"
                                  ),
                                ]
                              ),
                              _vm.isFilmSingle
                                ? _c(
                                    "b-dropdown-item",
                                    { on: { click: _vm.showWizard } },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.FormMSG(19948, "View wizard")
                                          ) +
                                          "\n\t\t\t\t\t\t"
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("HeaderNotifications", {
                        key: `${_vm.keyVersion}7`,
                        attrs: { "app-version": _vm.version },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-nav-item",
                    [
                      _c("DefaultHeaderDropdownAccnt", {
                        key: `${_vm.keyVersion}8`,
                        ref: "dropdown-accnt",
                        attrs: { "app-version": _vm.version },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "app-body",
          class: {
            "is-pwa": _vm.$isPwa(),
            "is-ios": _vm.$isIOSDevice(),
            "is-android": _vm.$isAndroidDevice(),
          },
        },
        [
          _c(
            "AppSidebar",
            {
              key: `${_vm.keyVersion}9`,
              attrs: { "dropdown-mode": "close", fixed: "" },
            },
            [
              _c("SidebarHeader", { key: `${_vm.keyVersion}10` }),
              _c("SidebarForm", { key: `${_vm.keyVersion}11` }),
              _c("SidebarNav", {
                key: `${_vm.keyVersion}12`,
                staticClass: "bg-secondary",
                attrs: { "nav-items": _vm.getNavItems },
              }),
              _c("SidebarFooter", { key: `${_vm.keyVersion}13` }),
              _c("SidebarMinimizer", { key: `${_vm.keyVersion}14` }),
            ],
            1
          ),
          _c(
            "main",
            { staticClass: "main" },
            [
              _vm.$screen.width < 576
                ? _c("Breadcrumb", { attrs: { list: _vm.list } })
                : _vm._e(),
              _c(
                "div",
                {
                  key: `${_vm.keyVersion}18`,
                  staticClass: "container-fluid pt-3",
                },
                [_c("router-view")],
                1
              ),
            ],
            1
          ),
          _c(
            "AppAside",
            { key: `${_vm.keyVersion}15`, attrs: { fixed: "" } },
            [_c("DefaultAside", { key: `${_vm.keyVersion}16` })],
            1
          ),
        ],
        1
      ),
      _c("TheFooter", { key: `${_vm.keyVersion}17` }, [
        _c("div", { staticClass: "ml-auto" }, [
          _c("span", { staticClass: "ml-1" }, [
            _vm._v("© " + _vm._s(_vm.copyright) + " "),
          ]),
          _c(
            "a",
            {
              attrs: {
                href: "https://www.thegreenshot.green",
                target: "_blank",
                rel: "noopener noreferrer",
              },
            },
            [_vm._v("TheGreenShot")]
          ),
        ]),
      ]),
      _c("tip-modal-component", { key: `${_vm.keyVersion}18` }),
      _c("help-modal-component", { key: `${_vm.keyVersion}19` }),
      _c("PreviewShowRoomImage", { key: `${_vm.keyVersion}20` }),
      _c("modal-wizard", { key: `${_vm.keyVersion}21` }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }