<template>
	<svg viewBox="0 0 32 32" aria-hidden="true" focusable="false">
		<path
			d="M16,0C7.2,0,0,7.2,0,16s7.2,16,16,16s16-7.2,16-16S24.8,0,16,0z M16,30C8.3,30,2,23.7,2,16C2,8.3,8.3,2,16,2s14,6.3,14,14C30,23.7,23.7,30,16,30z M24,16c0,4.4-3.6,8-8,8s-8-3.6-8-8s3.6-8,8-8S24,11.6,24,16z"
		/>
	</svg>
</template>

<script>
export default {
	name: 'TargetPinIconComponent'
};
</script>
