<style>
.wizard-modal .modal-dialog {
	position: absolute;
	top: 4rem;
	right: 1rem;
	width: 100%;
	max-width: 400px;
	margin: 0;
}
.wizard-modal .modal-content {
	border: none;
}
.wizard-modal .modal-body {
	border-radius: 0.3rem;
	background-repeat: no-repeat;
	background-position: top center;
	background-size: 100% 80%;
}
.wizard-modal .modal-footer {
	border-top: 0 none transparent;
}
.wizard-modal .wizard-modal-content strong {
	display: block;
	padding-top: 60px;
	padding-bottom: 24px;
}
</style>
<!--
    title="Tip of the day"
        ok-title="Thank you !"
            :title="this.FormMSG(10, 'Tip of the day')"
-->
<template>
	<b-modal
		header-class="header-class-modal-doc-package"
		:title="this.FormMSG(10, 'Tip of the day')"
		id="wizard-modal"
		ref="modal"
		size="xl"
		modal-class="wizard-modal"
		hide-header
		hide-footer
	>
		<div class="wizard-modal-content">
			<b-row>
				<b-col class="text-center">
					<img src="img/brand/thegreenshot-brand.png" width="150" alt="TheGreenShot" align="center" />
				</b-col>
			</b-row>
			<b-row class="mt-3">
				<b-col class="text-center">
					<p style="font-size: 20px; font-weight: 700">{{ FormMSG(9, 'Welcome to TheGreenShot') }}</p>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<div class="list-modal-wizard">
						<ol>
							<li>
								<p class="mb-0" @click="redirectTo('/profile?activeTabContract=true')">
									{{ FormMSG(19, 'Complete your Deal Memo') }}
								</p>
							</li>
							<li>
								<p class="mb-0" @click="redirectTo('/mytimesheets')">
									{{ FormMSG(20, 'Enter your worked hours') }}
								</p>
							</li>
							<li>
								<p class="mb-0" @click="redirectTo('/mytimesheets')">
									{{ FormMSG(21, 'Share your timesheets') }}
								</p>
							</li>
							<li>
								<p class="mb-0" @click="redirectTo('/myexpenses')">
									{{ FormMSG(25, 'Create your expenses') }}
								</p>
							</li>
							<li>
								<p class="mb-0" @click="redirectTo('/myexpenses?activeSecondTab=true')">
									{{ FormMSG(26, 'Share your expenses') }}
								</p>
							</li>
						</ol>
					</div>
				</b-col>
			</b-row>
			<p style="font-weight: 600; font-size: 13px">
				{{ FormMSG(30, 'At anytime, click on') }}
				<img v-if="$screen.width > 567 && lang !== 'fr'" class="navbar-brand-full" src="img/help-img.jpeg" width="50" alt="TheGreenShot" />
				<img v-if="$screen.width > 567 && lang === 'fr'" class="navbar-brand-full" src="img/aide-img.jpeg" width="50" alt="TheGreenShot" />
				<img v-if="$screen.width <= 567" class="navbar-brand-full" src="img/help-img-icon.jpeg" width="50" alt="TheGreenShot" />
				{{ FormMSG(31, 'to') }} : <br />
				- {{ FormMSG(32, 'Reopen this wizard') }} <br />
				- {{ FormMSG(33, 'See online help for such screen') }}
			</p>
			<b-row>
				<b-col>
					<b-form-checkbox id="checkbox-1" name="checkbox-1" v-model="valueShowWizard" @change="updateShowWizard">
						{{ FormMSG(34, 'Do not display this wizard when starting the application') }}
					</b-form-checkbox>
				</b-col>
			</b-row>
			<b-button variant="primary" class="float-right" @click="closeWizardModal()">
				{{ this.FormMSG(12, 'Close') }}
			</b-button>
		</div>
	</b-modal>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import { updateShowWizard } from '@/cruds/users.crud';
import { store } from '@/store';
import globalMixin from '@/mixins/global.mixin';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';

export default {
	name: 'ModalWizard',
	mixins: [languageMessages, isSingleProjectMixin, globalMixin],

	data() {
		return {
			isOpen: false
		};
	},
	computed: {
		valueShowWizard: {
			get() {
				return store.state.myProfile && !store.state.myProfile.showWizard;
			},
			set(val) {
				return val;
			}
		}
	},
	mounted() {
		setTimeout(
			(scope) => {
				if (scope.store.isFilmSingleUserFree() || scope.store.isFilmSingleUser()) {
					if (!scope.parent.$cookies.isKey('wizardAlreadyShow')) {
						if (scope.store.state.myProfile && scope.store.state.myProfile.showWizard === true) {
							scope.parent.$bvModal.show('wizard-modal');
							scope.parent.$cookies.set('wizardAlreadyShow', true);
						}
					}
				}
			},
			1000,
			{ parent: this, store }
		);
	},
	methods: {
		redirectTo(url) {
			this.$router.push(url);
			this.$bvModal.hide('wizard-modal');
		},
		async updateShowWizard(payload) {
			await updateShowWizard({
				userId: store.state.myProfile.id,
				showWizard: !payload
			});
		},
		closeWizardModal() {
			this.$bvModal.hide('wizard-modal');
		}
	}
};
</script>
