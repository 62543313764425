<template>
	<div class="comments-panel-container" :class="rendCommentsListClass">
		<div class="content">
			<div class="content-panel-holder">
				<!-- <div class="content-panel-header content_sb_middle">
					<div class="left-options content_sb_middle_start" />
					<div class="right-options content_sb_middle_end">
						<button
							class="option-icon-holder"
							:class="{ 'is-disabled': manager.states.isAnnotationToolActive }"
							:disabled="manager.states.isAnnotationToolActive"
							@click="handleAddLike"
						>
							<HandKLiketIcon />
						</button>
						<button class="option-icon-holder" :class="{ 'is-active': manager.states.isAnnotationToolActive }" @click="handleAddAnnotation">
							<TargetPinIcon />
						</button>
						<button v-if="isCommentPanelOpen" class="option-icon-holder" @click="handleCloseCommentsPanel">
							<ArrowCloseRightIcon />
						</button>
					</div>
				</div> -->

				<div class="content-panel-body">
					<div class="comments-list-area">
						<CommentsList :manager="manager" />
					</div>
				</div>

				<div class="content-panel-footer">
					<div class="comment-form-area">
						<CommentComposer :manager="manager" />

						<!-- <div class="section">
							<b-button variant="primary" :disabled="isAddCommentDisabled" @click="handleAddComment">
								<b-spinner v-show="manager.states.isCommentSendingLoading" small />
								<span v-show="!manager.states.isCommentSendingLoading">
									{{ FormMSG(5, 'Add Comment') }}
								</span>
							</b-button>
						</div> -->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import CommentsList from '@/components/Packages/FIlesPreviewer/components/Comments/CommentsList';
// import ArrowCloseRightIcon from '@/components/icons/arrow-close-right'
// import HandKLiketIcon from '@/components/icons/hand-like'
// import TargetPinIcon from '@/components/icons/target-pin'
import languageMessages from '@/mixins/languageMessages';
import CommentComposer from '@/components/Packages/FIlesPreviewer/components/Comments/CommentComposer';

export default {
	name: 'CommentPanelComponent',
	components: { CommentsList, CommentComposer },
	mixins: [languageMessages],
	props: {
		manager: {
			type: Object,
			required: true,
			default: () => {}
		}
	},
	computed: {
		/**
		 * @return {Object}
		 */
		rendCommentsListClass() {
			return {
				'is-open': this.manager.states.isCommentPanelOpen
			};
		},

		/**
		 * @return {Boolean}
		 */
		hasAnnotationEnabled() {
			return this.manager.states.options.annotationEnabled;
		},

		isCommentPanelOpen: {
			/**
			 * @return {Boolean}
			 */
			get() {
				return this.manager.states.isCommentPanelOpen;
			},
			/**
			 * @param {Boolean} status
			 */
			set(status) {
				this.manager.dispatch('setCommentPanelOpen', status);
			}
		}
	},
	watch: {
		'manager.states.isCommentPanelOpen'(status) {
			if (!status) return;
			this.getCommentsList();
		}
	},
	methods: {
		async getCommentsList() {
			const xid = this.manager.states.currentActiveSlide;
			const docDeliId = this.manager.states.currFileDetails.firstDeliveryId;
			await this.manager.dispatch('getCommentsList', { xid, docDeliId });
		},

		handleClosePanel() {
			if (!this.manager.states.isCommentPanelOpen) return;
			this.manager.dispatch('setCommentPanelOpen', false);
			this.manager.dispatch('setCurrentActiveSlideStatus', true);
		},

		handleAddAnnotation() {
			const _c = this.manager.states.isAnnotationToolActive;
			this.manager.dispatch(_c ? 'destroyAnnotationTool' : 'setAnnotationToolActive');
		},

		handleCloseCommentsPanel() {
			this.isCommentPanelOpen = false;
			this.manager.dispatch('destroyAnnotationTool');
			this.manager.dispatch('setCurrentActiveSlideStatus', false);
		},

		async handleAddLike() {
			await this.manager.dispatch('addLike');
		}
	}
};
</script>

<style lang="scss" scoped>
.comments-panel-container {
	width: 0;
	height: calc(100% - 61px);
	position: fixed;
	top: 61px;
	left: auto;
	right: 0;
	bottom: 0;
	overflow: hidden;
	border-left: 1px solid transparent;
	transition: border-color ease-in-out 300ms;

	.content {
		position: absolute;
		border-left: 1px solid transparent;
		width: 600px;
		height: 100%;
		right: 0;
		left: auto;
		transition: transform ease-in-out 300ms;
		transform: translateX(100px);
		opacity: 0;
		background-color: hsla(0, 0%, 100%, 0.9);
		-webkit-backdrop-filter: saturate(180%) blur(15px);
		backdrop-filter: saturate(180%) blur(15px);
		display: flex;
		flex: 1 1 auto;
		flex-direction: column;
		min-height: 1px;

		.content-panel-holder {
			display: flex;
			flex: 1 1 auto;
			flex-direction: column;
			min-height: 1px;

			.content-panel- {
				&header {
					padding-right: 15px;
					height: 50px;
					.option-icon-holder {
						width: auto;
						background-color: transparent;
						font-size: 12px;
						height: 28px;
						display: flex;
						align-items: center;
						line-height: 28px;
						padding: 0 5px;
						transition: background-color ease-in-out 300ms;
						border-radius: 4px;
						border: none;
						&:not(:last-child) {
							margin-right: 5px;
						}
						&:not(.is-disabled) {
							cursor: pointer;
						}
						&:hover {
							background-color: #f5f5f5;
						}
						&.is-disabled > * {
							opacity: 0.4;
						}
						svg {
							display: block;
							height: 16px;
							width: 16px;
						}
					}
				}

				&body {
					display: flex;
					flex: 1 1 auto;
					flex-direction: column;
					min-height: 1px;
					position: relative;
				}

				&footer {
					flex-shrink: 0;
					padding-left: 10px;
				}
			}
		}

		.comments-list-area,
		.comment-form-area {
			padding: 15px;
		}
		.comments-list-area {
			padding: 15px;
			display: flex;
			flex: 1 1 auto;
			flex-direction: column;
			min-height: 1px;
		}
		.comment-form-area .section {
			margin-top: 15px;
			width: 100%;
			display: flex;
			justify-content: flex-end;
			vertical-align: middle;
			.btn:not(:last-child) {
				margin-right: 15px;
			}
		}
	}
	&.is-open {
		width: 601px;
		background-color: transparent;
		.content {
			transform: translateX(0);
			opacity: 1;
			border-left-color: #333;
		}
	}
}
</style>
