<style>
.project-toggler .dropdown-menu {
	margin: 0;
	padding: 0;
}

.project-toggler .label-only {
	color: #007949;
	font-weight: bold;
	display: inline-block;
	background-color: #eee;
	border: 1px solid #eee;
	padding: 0.25rem 0.5rem;
	font-size: 0.875rem;
	line-height: 1.5;
	border-radius: 0.2rem;
}
</style>

<template>
	<div class="project-toggler dropdown" v-if="myProjects && myProjects.length > 1">
		<!-- {{ $screen.height }} -->
		<b-dropdown variant="outline-secondary" size="sm" :text="myProject ? myProject.titre : ''">
			<b-dropdown-header id="dropdown-header-label">
				{{ this.FormMSG(1, 'Switch to another project') }}
			</b-dropdown-header>
			<b-dropdown-item-button
				v-for="project in myProjects"
				:key="project.id"
				action
				v-on:click="changeProjectAction(project)"
				href="#"
				v-bind:active="myProject && myProject.id === project.id"
				>{{ project.titre }}</b-dropdown-item-button
			>
		</b-dropdown>
	</div>
	<div class="project-toggler" v-else>
		<span class="label-only">{{ myProject ? myProject.titre : '' }}</span>
	</div>
</template>

<script>
import gql from 'graphql-tag';
import { store } from '@/store';
import { myContracts } from '@/cruds/contract.crud';
// import { myProjects, myProject } from '@/cruds/project.crud.js'
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import { getParamVersion } from '@/shared/version';
import { mapActions } from 'vuex';
import { query_myProjects, query_myProject, changeMyProject } from '@/cruds/project.crud';

export default {
	name: 'HeaderProjectToggler',
	mixins: [languageMessages, globalMixin],
	apollo: {
		myProjects: {
			query: query_myProjects,
			fetchPolicy: 'no-cache',
			error(err) {
				console.log('err:', err);
				this.erreur = err;
			}
		},
		myProject: {
			query: query_myProject,
			fetchPolicy: 'no-cache',
			error(err) {
				console.log('err:', err);
				this.erreur = err;
			}
		}
	},
	data: function () {
		return {
			// Initialize your apollo data
			myProjects: {},
			myProject: {},
			erreur: {},
			myerr: '',
			shared: store.state
			// myProjects: [],
			// myProject: null
		};
	},

	methods: {
		...mapActions({
			getNotifications: 'header/getNotifications',
			initFilterActive: 'global-filter/initFilterActive',
			getDocuments: 'document/getDocuments'
		}),
		async changeProject(project) {
			// position on dashboar screen first
			// this.$router.push("/")
			const id = parseInt(project.id, 10);
			try {
				const result = await changeMyProject(id);
				const response = await store.onNewToken(this.$apollo.provider.defaultClient, result); // executer onNewToken avant de storer le nouveau projet, sinon supprimé
				if (response) {
					store.setProjectAction(); // project parameters is not in use, removed it
					this.initFilterActive();
					const tmp = await myContracts();
					// console.log("in myContract DefaultHeaderDropdownAccnt tmp:",tmp);
					store.state.myContracts = tmp;
					this.myerr = '';

					await this.getNotifications(project.id);
					this.getDocuments({
						userId: +store.userID()
					});

					// reload project data.
					// await this.loadProjectsData();

					// refresh the page and redirect to project dashboard
					// this.$router.push('/my-dashboard?v=' + getParamVersion()).catch(() => {});
					this.$router.push({ name: 'My Dashboard', query: { v: getParamVersion() } }).catch(() => {
						this.$router.go();
					});
				}
			} catch (err) {
				this.erreur = err;
			}
		},
		// async loadProjectsData() {
		// 	try {
		// 		this.myProjects = await getAllMyProjectsSummary();
		// 		this.myProject = await getMyProjectSummary();
		// 	} catch (err) {
		// 		this.erreur = err;
		// 		console.log('error get project header', err);
		// 	}
		// },
		async changeProjectAction(project) {
			await this.showLoader(this.changeProject, 'default-container', false, project);
		}
	}
	// async created() {
	// 	await this.loadProjectsData();
	// }
};
</script>
<style scoped>
::v-deep .dropdown-menu {
	max-height: 650px;
	overflow-y: auto;
}
</style>
