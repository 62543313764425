<template>
	<div class="submit-file-change-btn--component-wrapper">
		<b-button id="tooltip-target-87557897" variant="primary" :disabled="isDisbaled" @click="handleSubmitPdfChanges">
			<b-spinner v-if="manager.states.isUploadFileLoading" small style="margin-right: 15px" />
			{{ FormMSG(8997897, "Submit PDF's changes") }}
		</b-button>
		<b-tooltip target="tooltip-target-87557897" triggers="hover">
			{{ FormMSG(908908, 'All your changes in the pdf gonna be saved and send to the administration') }}
		</b-tooltip>
	</div>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';

export default {
	name: 'SubmuitFileChangeBtnComponent',
	mixins: [languageMessages],
	props: {
		manager: {
			type: Object,
			required: true,
			default: () => {}
		}
	},
	computed: {
		/**
		 * @return {String}
		 */
		changedFileXid() {
			return this.manager.states.hasDocumentChangesCanBeSubmit;
		},

		/**
		 * @return {String}
		 */
		currentActiveSlide() {
			return this.manager.states.currentActiveSlide;
		},

		/**
		 * @return {Boolean}
		 */
		isDisbaled() {
			return this.changedFileXid !== this.currentActiveSlide;
		}
	},
	methods: {
		handleSubmitPdfChanges() {
			this.manager.stream.$emit('submit-pdf-changes', this.currentActiveSlide);
		}
	}
};
</script>

<style lang="scss" scoped>
.submit-file-change-btn--component-wrapper {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding-left: 10px;

	button.btn {
		white-space: nowrap;
	}
}
</style>
