var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "commentsListArea",
      staticClass: "comment-list-items-wrapper d-inline-block",
      staticStyle: { width: "100%" },
      attrs: {
        show: _vm.manager.states.isCommentListLoading,
        rounded: "",
        opacity: "0.6",
        "spinner-small": "",
        "spinner-variant": "primary",
      },
    },
    [
      _vm.$has(_vm.commentsList) && _vm.commentsList.length
        ? _c(
            "ul",
            { staticStyle: { "padding-left": "0" } },
            _vm._l(_vm.commentsList, function (comment, i) {
              return _c("CommentListItem", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: comment.message.length,
                    expression: "comment.message.length",
                  },
                ],
                key: i,
                attrs: { manager: _vm.manager, comment: comment },
              })
            }),
            1
          )
        : _c("span", [_vm._v(_vm._s(_vm.FormMSG(4, "No comment")))]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }