var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "pins-holder",
      class: { "has-pin-hovered": _vm.$has(_vm.manager.states.currPinHovered) },
    },
    _vm._l(_vm.positionedPins, function (pin, i) {
      return _c(
        "span",
        {
          key: i,
          ref: _vm.rendPinRef(pin.xid),
          refInFor: true,
          staticClass: "positioned-pin",
          class: {
            "is-currently-hovered":
              _vm.manager.states.currPinHovered === pin.pinInfoCode,
          },
          style: _vm.rendPinStyle(pin),
        },
        [_vm._v("\n\t\t" + _vm._s(pin.pinInfoCode) + "\n\t")]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }