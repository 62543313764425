<template>
	<div>
		<transition name="slide">
			<div
				v-if="isShowRoomOpen"
				:key="showRoomToken"
				class="main-layer--imageshowroom--component-wrapper"
				:class="rendMainLayerClass"
				:style="rendMainLayerStyle"
			>
				<div class="content" :class="`content-type--${manager.states.options.presentationMode}`">
					<MetaDataHeadBar @close="handleCloseShowRoomMainModal" ref="documentViewerModal">
						<template v-if="hasDocuments" slot="actions">
							<b-button
								v-if="!manager.states.options.hideCropButton"
								id="tooltip-target-1675"
								class="btn bg-transparent border-0 px-0"
								@click="handleCropPicture"
								size="sm"
								style="width: 42px; margin-right: 8px"
							>
								<!-- <i class="fa fa-crop" /> -->
								<component :is="getLucideIcon('Crop')" color="#5B6164D6" :size="18" :stroke-width="2.5" />
							</b-button>
							<b-tooltip target="tooltip-target-87557" triggers="hover">
								{{ FormMSG(564, 'Delete this picture') }}
							</b-tooltip>
							<b-button
								v-if="!manager.states.options.hideDeleteButton"
								id="tooltip-target-87557"
								:disabled="isImageDeleteingLoading"
								class="btn bg-transparent border-0 px-0"
								@click="handleDeletePicture"
								size="sm"
								style="width: 42px"
							>
								<b-spinner style="width: 18px; height: 18px" v-if="isImageDeleteingLoading" small />
								<!-- <i v-show="!isImageDeleteingLoading" class="icon-trash" /> -->
								<component :is="getLucideIcon('Trash2')" color="#EA4E2C" :size="18" :stroke-width="2.5" v-else />
							</b-button>
							<b-tooltip target="tooltip-target-1675" triggers="hover">
								{{ FormMSG(8976556, 'Crop this picture') }}
							</b-tooltip>
							<StatsButtons v-if="!isStatsButtonHide" :manager="manager" />
							<!-- <SubmitFileChangeBtnTool
							v-if="manager.states.options.presentationMode === 'doc' && manager.states.currentActiveSlideExt === 'pdf'"
							:manager="manager"
						/> -->
							<SignDocumentTool
								:manager="manager"
								@sign-document-tool:watingForOodrive="onWatingForOodrive"
								@sign-document-tool:closeViewer="onViewerClosed"
								@sign-document-tool:documentUpdateStatus="onOodriveSignContractStatusUpdated"
							/>
							<b-button
								size="sm"
								class="btn bg-transparent border-0"
								:disabled="isCurrFileDownloading"
								v-b-tooltip.hover.left
								:title="FormMSG(6, 'Download')"
								@click="handleCurrDocDownload"
							>
								<b-spinner v-if="isCurrFileDownloading" small />
								<component v-if="!isCurrFileDownloading" :is="getLucideIcon(ICONS.DOWNLOAD.name)" :color="ICONS.EDIT.color" :size="20" />
							</b-button>
						</template>
					</MetaDataHeadBar>
					<div class="content-body-container" :class="{ 'has-horizontal-style': manager.states.options.presentationMode !== 'image' }">
						<b-alert
							v-if="!hasDocuments"
							show
							variant="info"
							style="position: absolute; left: 0; right: 0; top: 200px; margin: auto; max-width: 200px"
						>
							{{ FormMSG(7867867, 'No element to show') }}
						</b-alert>
						<CarouselPanel
							:key="`carousel_panel_${showRoomToken}`"
							:id="`carousel_panel_${showRoomToken}`"
							:manager="manager"
							@close="handleCloseShowRoomMainModal"
						/>
						<ImagesListThumbnails v-show="manager.states.isCarouselControlActive" :manager="manager" />
					</div>
				</div>
				<!-- Warning on delete images -->
				<delete-image-modal
					v-model="showDeleteImageWarning"
					@delete-image-modal:hide="hideImageDeleteWarning"
					@delete-image-modal:confirm="confirmImageDelete"
				/>
				<!-- Warning on delete images -->
				<CommentPanel v-if="hasCommentsActive" :manager="manager" />
				<PinCommentModal v-if="hasCommentsActive" :manager="manager" />
				<div id="overlaySignDocument" v-if="isLoading" class="text-center bg-transparent">
					<p><loading :active.sync="isLoading" loader="bars" color="#00A09C" :width="64" /></p>
					<p id="cancel-label" class="loading-text">{{ FormMSG(122, 'Please wait...') }}</p>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
import { isNil, makeID, isObj } from '@/shared/utils';
import ImageShowRoomManager from '@/components/Packages/FIlesPreviewer/imageShowroom.manager';
import MetaDataHeadBar from '@/components/Packages/FIlesPreviewer/components/MetaDataHeadBar';
import CarouselPanel from '@/components/Packages/FIlesPreviewer/components/CarouselPanel';
import ImagesListThumbnails from '@/components/Packages/FIlesPreviewer/components/ImagesListThumbnails';
import CommentPanel from '@/components/Packages/FIlesPreviewer/components/CommentPanel';
import StatsButtons from '@/components/Packages/FIlesPreviewer/components/StatsButtons';
import PinCommentModal from '@/components/Packages/FIlesPreviewer/components/PinsAnnotations/PinCommentModal';
import SignDocumentTool from '@/components/Packages/FIlesPreviewer/components/Tools/SignDocument';
import SubmitFileChangeBtnTool from '@/components/Packages/FIlesPreviewer/components/Tools/SubmitFileChangeBtn';
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import Loading from 'vue-loading-overlay';
import { deleteImg } from '@/cruds/images.crud';
import DeleteImageModal from '@/components/Modals/DeleteImageModal';

export default {
	name: 'MainModalImageShowroomComponent',
	components: {
		CarouselPanel,
		MetaDataHeadBar,
		ImagesListThumbnails,
		StatsButtons,
		PinCommentModal,
		CommentPanel,
		SignDocumentTool,
		SubmitFileChangeBtnTool,
		DeleteImageModal,
		Loading
	},
	mixins: [globalMixin, languageMessages],
	data() {
		const manager = new ImageShowRoomManager(this, {});
		return {
			showRoomToken: makeID(10),
			manager,
			isShowRoomOpen: false,
			refreshToken: 0,
			previerName: 'previewer_default_name__' + makeID(10),
			isCurrFileDownloading: false,
			isImageDeleteingLoading: false,
			isLoading: false,
			showDeleteImageWarning: false,
			imageToDelete: {}
		};
	},
	computed: {
		imagesList: {
			/**
			 * @return {Array}
			 */
			get() {
				return this.manager.states.imagesList;
			},
			/**
			 * @param {Array} images
			 */
			set(images) {
				this.manager.dispatch('setImagesList', images);
			}
		},
		isCommentPanelOpen: {
			/**
			 * @return {Boolean}
			 */
			get() {
				return this.manager.states.isCommentPanelOpen;
			},
			/**
			 * @param {Boolean} status
			 */
			set(status) {
				this.manager.dispatch('setCommentPanelOpen', status);
			}
		},
		/**
		 * @return {Boolean}
		 */
		isStatsButtonHide() {
			const { hideLikeDislike, hideCommentButton, hideDisLikeButton } = this.manager.states.options;
			return hideLikeDislike && hideCommentButton && hideDisLikeButton;
		},
		/**
		 * @return {Boolean}
		 */
		hasCommentsActive() {
			return this.manager.states.hasCommentsActive;
		},
		/**
		 * @return {Object}
		 */
		rendMainLayerClass() {
			return {
				'is-comments-panel-open': this.isCommentPanelOpen,
				'is-pwa': this.$isPwa()
			};
		},
		/**
		 * @return {Object}
		 */
		rendMainLayerStyle() {
			return {
				'z-index': this.manager.states.options.zIndex + ' ' + '!important'
			};
		},
		isCommentComposerFocus: {
			/**
			 * @return {Boolean}
			 */
			get() {
				return this.manager.states.isCommentComposerFocus;
			},
			/**
			 * @param {Boolean} status
			 */
			set(status) {
				this.manager.dispatch('toggleCommentComposerFocusStatus', status);
			}
		},
		/**
		 * @return {Object}
		 */
		previewerOptions() {
			return this.manager.states.options;
		},

		/**
		 * @return {Boolean}
		 */
		hasDocuments() {
			const { imagesList } = this.manager.states || {};
			return !isNil(imagesList) && imagesList.length > 0;
		}
	},
	watch: {
		isShowRoomOpen: {
			/**
			 * @param {Boolean} status
			 */
			handler(status) {
				this.showRoomToken = makeID(10);
				this.$store.dispatch('app/setOpenShowRoomPictureOpenState', status);
				this.manager.states.hasDocumentChangesCanBeSubmit = false;
				this.emitPreviewerClosed(status);
			},
			immediate: true
		}
	},
	created() {
		this.$bus.$on('update-images-list-showroom', (images) => {
			this.buildImagesList(images);
		});
	},
	mounted() {
		this.$bus.$on('open-showroom-images', ({ name, images, focusIndex = null, options = {} }) => {
			this.setPreviewerName(name);
			this.refreshToken = this.refreshToken + 1;
			this.buildImagesList(images, focusIndex);
			this.manager.dispatch('initShowRoomOptions', options);
			this.isShowRoomOpen = true;
		});

		const vm = this;
		document.addEventListener('keyup', (e) => {
			if (e.keyCode === 27 && vm.isShowRoomOpen) {
				if (vm.isCommentPanelOpen) {
					if (vm.isCommentComposerFocus) {
						vm.isCommentComposerFocus = false;
					} else {
						vm.isCommentPanelOpen = false;
					}

					if (vm.manager.states.isAnnotationToolActive) {
						vm.manager.dispatch('destroyAnnotationTool');
					}
				} else {
					vm.isShowRoomOpen = false;
				}
			}
		});
	},
	methods: {
		forceDownload(xid) {
			const url = process.env.VUE_APP_GQL + '/images/' + xid;
			axios.get(url, { responseType: 'blob' }).then((response) => {
				const link = window.URL.createObjectURL(new Blob([response.data]));
				const anchor = document.createElement('a');
				anchor.setAttribute('href', link);
				anchor.setAttribute('target', '_blank');
				anchor.setAttribute('download', xid);
				document.body.appendChild(anchor);
				anchor.click();
				document.body.removeChild(anchor);
			});
		},
		async handleCurrDocDownload() {
			this.isCurrFileDownloading = true;
			const _cd = this.manager.states.currFileDetails;
			if (isNil(_cd)) return;
			await this.downloadUrl(_cd.src, _cd.fileName);
			this.isCurrFileDownloading = false;
		},
		handleCloseShowRoomMainModal() {
			// refresh the list of the users in the delivery list to show status change to read.
			if (['Document Details', 'Document Deliveries'].includes(this.$route.name)) {
				this.$bus.$emit('document-users-deliveries-refresh');
			}
			this.manager.dispatch('setCommentPanelOpen', false);
			this.isShowRoomOpen = false;
		},
		/**
		 * @param {String} name
		 */
		setPreviewerName(name) {
			this.previerName = name;
		},
		/**
		 * @param {Boolean} status
		 */
		emitPreviewerClosed(status) {
			if (status) return;
			const busPath = `on_documents_previewer_event__${this.previerName}`;
			this.$bus.$emit(busPath, { type: 'close', any: { status } });
		},
		/**
		 * @param {Array} images
		 * @param {Number|null} focusIndex
		 */
		buildImagesList(images, focusIndex) {
			const imagesList = [];
			this.manager.dispatch('setImagesList', []);
			const e = process.env.VUE_APP_PATH_IMG_URL;
			for (let i = 0; i < images.length; i++) {
				const file = images[i];

				const el = isObj(file) ? file : { xid: file, src: e + file, ext: 'png' };

				imagesList.push(el);
			}
			this.manager.dispatch('setImagesList', imagesList);
			const __i = isNil(focusIndex) ? 0 : focusIndex;
			if (!isNil(imagesList[__i])) {
				// this.manager.dispatch('getCommentStats', imagesList[__i].xid);
				this.setActiveCarouselImage(imagesList[__i]);
			}
		},
		/**
		 * @param {Object} imgObj
		 */
		setActiveCarouselImage(imgObj) {
			this.manager.states.currentActiveSlideExt = imgObj.ext;
			this.manager.dispatch('setCurrentActiveSlide', imgObj.xid);
		},
		handleShowCommentsPanel() {
			this.isCommentPanelOpen = !this.isCommentPanelOpen;
			this.manager.dispatch('setCurrentActiveSlideStatus', !this.isCommentPanelOpen);
		},
		handleCropPicture() {
			this.$bus.$emit('open-crop-modal-module', {
				img: this.manager.states.currentActiveSlide,
				index: this.manager.states.currentActiveIndex
			});
		},
		handleDeletePicture() {
			/** @type {String} xid */
			const { xid } = this.manager.states.currFileDetails || {};
			if (isNil(xid)) throw new Error("Something whent wrong, can't find the file's xid");
			this.imageToDelete = xid;
			this.showDeleteImageWarning = true;

			/** @type {String} txt */
			// const txt = this.FormMSG(987987, 'Please confirm that you want delete this document');

			// this.$bvModal
			// 	.msgBoxConfirm(txt, {
			// 		title: this.FormMSG(56561, 'Please Confirm'),
			// 		size: 'sm',
			// 		buttonSize: 'sm',
			// 		okVariant: 'danger',
			// 		okTitle: this.FormMSG(56562, 'YES'),
			// 		cancelTitle: this.FormMSG(56563, 'NO'),
			// 		footerClass: 'p-2',
			// 		hideHeaderClose: false,
			// 		centered: false
			// 	})
			// 	.then(async (v) => {
			// 		if (!v) return;
			// 		this.isImageDeleteingLoading = true;
			// 		try {
			// 			/** @type {Array} refreshedImages */
			// 			const refreshedImages = await deleteImg(xid);
			// 			this.manager.dispatch('setImagesList', refreshedImages);
			// 			this.$bus.$emit('filepreviewer:image-deleted-from-parent', { xid, refreshedImages });
			// 		} catch (err) {
			// 			console.error(err);
			// 			throw new Error('Something whent wrong during the deletion');
			// 			this.$toast({
			// 				message: this.FormMSG(410, 'Something whent wrong during the deletion'),
			// 				title: this.FormMSG(411, 'Error'),
			// 				variant: 'danger'
			// 			});
			// 		}
			// 		this.isImageDeleteingLoading = false;
			// 	})
			// 	.catch((err) => console.error(err));
			// this.$bus.$emit('set-xid-delete-modal-module', this.manager.states.currentActiveSlide);
		},
		async confirmImageDelete() {
			this.isImageDeleteingLoading = true;
			try {
				/** @type {Array} refreshedImages */
				const refreshedImages = await deleteImg(this.imageToDelete);
				const images = this.imageToDelete;
				// this.manager.dispatch('setImagesList', refreshedImages);
				this.buildImagesList([...refreshedImages].reverse());
				this.$bus.$emit('filepreviewer:image-deleted-from-parent', { images, refreshedImages });
			} catch (err) {
				console.error(err);
				this.$toast({
					message: this.FormMSG(410, 'Something whent wrong during the deletion'),
					title: this.FormMSG(411, 'Error'),
					variant: 'danger'
				});
				throw new Error('Something whent wrong during the deletion');
			}
			this.hideImageDeleteWarning();
			this.isImageDeleteingLoading = false;
		},
		hideImageDeleteWarning() {
			this.imageToDelete = {};
			this.showDeleteImageWarning = false;
		},
		onWatingForOodrive(payload) {
			this.isLoading = payload;
		},
		onViewerClosed() {
			this.handleCloseShowRoomMainModal();
		},
		onOodriveSignContractStatusUpdated(payload) {
			// this.handleCloseShowRoomMainModal();
			// this.$bus.$emit('tool-viewer-doc:reload', payload);
			this.$refs['documentViewerModal'].$refs['documentViewerModalClose'].click();
		}
	}
};
</script>

<style lang="scss" scoped>
@import './../../../../assets/scss/tools/mixins';
@import './../../../../assets/scss/tools/media-queries';

$sideBarWidth: 180px;
.main-layer--imageshowroom--component-wrapper {
	align-items: center;
	display: flex;
	flex-direction: column;
	@include pos(f, 0);
	// z-index: 9999 !important;

	&.is-pwa {
		padding-top: 40px;
	}

	& > .content {
		background-color: hsla(0, 0%, 100%, 0.9);
		-webkit-backdrop-filter: saturate(180%) blur(15px);
		backdrop-filter: saturate(180%) blur(15px);
		display: flex;
		flex: 1 1 auto;
		flex-direction: column;
		min-height: 1px;
		min-width: 1px;
		width: 100%;

		& > .content-body-container {
			max-height: calc(100vh - 61px);
			display: flex;
			flex: 1 1 auto;
			flex-direction: column;

			&.has-horizontal-style {
				flex-direction: row;
				& > .images-list-thumbnails--imagesshowroom--component-wrapper {
					overflow-x: scroll;
					overflow-y: auto;

					order: 1;
					margin-left: 0;
					margin-right: 0;
					flex-direction: column;
					padding-top: 20px;
					@include size($sideBarWidth, 100%);
					display: flex;
					flex-wrap: nowrap;
					align-items: center;
				}
				& > .main-container--image-show-room {
					order: 2;
					width: calc(100% - #{$sideBarWidth});
				}
			}

			&,
			&.has-horizontal-style {
				@include max-screen(600px) {
					flex-direction: column;

					& > .main-container--image-show-room {
						order: 1;
					}
					& > .images-list-thumbnails--imagesshowroom--component-wrapper {
						order: 2;
						padding-top: 0;
						flex-wrap: nowrap;
						align-items: center;
						margin-left: 0;
						margin-right: 0;
						justify-content: flex-start;
						flex-direction: row;

						@include size(calc(100% - 100px), 40px);
						@include pos(a, auto, 0, 10px, 0);
						overflow-y: scroll;
						overflow-x: auto;
						z-index: 10000;
					}
				}
			}
		}
	}
}
#overlaySignDocument {
	@include pos(f, 0);
	@include size(f);
	align-items: center;
	justify-content: center;
	display: flex;
	flex-direction: column;
	background-color: rgba(6, 38, 62, 0.2);
}

.loading-text {
	color: #06263e;
	font-size: 1rem;
	font-weight: 600;
	letter-spacing: 0.07rem;
}
</style>
