var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      staticClass: "modal-warning",
      attrs: {
        "header-class": "header-class-modal-doc-package",
        "header-bg-variant": "warning",
        title: _vm.FormMSG(1, "Please Confirm"),
        size: "sm",
        "hide-header-close": "",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        centered: "",
        static: "",
      },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function () {
            return [
              _c(
                "div",
                { staticClass: "w-100" },
                [
                  _c(
                    "b-row",
                    { staticClass: "padding-c-1" },
                    [
                      _c("b-col", { attrs: { cols: "12" } }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex flex-row justify-content-center align-items-center w-100",
                            staticStyle: { gap: "20px" },
                          },
                          [
                            _c(
                              "b-button",
                              {
                                staticClass: "w-100-px",
                                attrs: {
                                  size: "sm",
                                  variant: "outline-danger",
                                },
                                on: { click: _vm.hideImageDeleteWarning },
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t" +
                                    _vm._s(_vm.FormMSG(3, "NO")) +
                                    "\n\t\t\t\t\t\t"
                                ),
                              ]
                            ),
                            _c(
                              "b-button",
                              {
                                staticClass: "w-100-px",
                                attrs: { size: "sm", variant: "success" },
                                on: { click: _vm.confirmImageDelete },
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t" +
                                    _vm._s(_vm.FormMSG(4, "YES")) +
                                    "\n\t\t\t\t\t\t"
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [
      _c("div", [
        _vm._v(
          _vm._s(
            _vm.FormMSG(2, "Please confirm that you want delete this image")
          )
        ),
        _c("br"),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }