var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "comments-panel-container",
      class: _vm.rendCommentsListClass,
    },
    [
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "content-panel-holder" }, [
          _c("div", { staticClass: "content-panel-body" }, [
            _c(
              "div",
              { staticClass: "comments-list-area" },
              [_c("CommentsList", { attrs: { manager: _vm.manager } })],
              1
            ),
          ]),
          _c("div", { staticClass: "content-panel-footer" }, [
            _c(
              "div",
              { staticClass: "comment-form-area" },
              [_c("CommentComposer", { attrs: { manager: _vm.manager } })],
              1
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }