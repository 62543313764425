<template>
	<b-modal
		header-class="header-class-modal-doc-package"
		ref="modal"
		v-model="isPinModalOpen"
		ok-variant="success"
		:title="FormMSG(111, 'Comment this zone')"
		:ok-title="FormMSG(99, 'Add Comment')"
		:cancel-title="FormMSG(2, 'Cancel')"
		@ok="handleSubmitModal"
		@cancel="handleCloseModal"
		@hidden="handleCloseModal"
	>
		<b-row>
			<b-col>
				<b-form-textarea
					autofocus
					ref="pinCommentComposerRef"
					v-model="commentPayload.message"
					:placeholder="FormMSG(38, 'Enter comment...')"
					rows="3"
					required
				/>
			</b-col>
		</b-row>
	</b-modal>
</template>

<script>
import Vue from 'vue';
import languageMessages from '@/mixins/languageMessages';

const defaultComment = { message: null };

export default {
	name: 'PinCommentModalComponent',
	mixins: [languageMessages],
	props: {
		manager: {
			type: Object,
			required: true,
			default: () => {}
		}
	},
	computed: {
		commentPayload: {
			/**
			 * @return {Object}
			 */
			get() {
				return this.manager.states.commentPayload;
			},
			/**
			 * @param {Object} payload
			 */
			set(payload) {
				this.manager.dispatch('setCommentPayload', payload);
			}
		},

		/**
		 * @return {Boolean}
		 */
		isAddCommentDisabled() {
			const { message } = this.commentPayload;
			return this.$nil(message) || message === '';
		},

		isPinModalOpen: {
			/**
			 * @return {Boolean}
			 */
			get() {
				return this.manager.states.isPinModalOpen;
			},
			/**
			 * @param {Boolean} status
			 */
			set(status) {
				this.manager.dispatch('setPinModalCommentState', status);
			}
		}
	},
	watch: {
		isPinModalOpen: {
			/**
			 * @param {Boolean} status
			 */
			handler(status) {
				if (!status) return;
				Vue.nextTick(() => this.focusInput());
			},
			immediate: true
		}
	},
	methods: {
		closeModal() {
			this.commentPayload = Object.assign({}, defaultComment);
			this.manager.dispatch('destroyAnnotationTool');
			this.isPinModalOpen = false;
		},

		focusInput() {
			this.$refs.pinCommentComposerRef.focus();
		},

		handleCloseModal() {
			this.closeModal();
		},

		async handleSubmitModal() {
			const xid = this.manager.states.currentActiveSlide;
			await this.manager.dispatch('addComment', {
				xid,
				message: this.commentPayload.message
			});
			if (this.manager.states.isAnnotationToolActive) this.manager.dispatch('toggleAnnotationToolActive', false);
			// await this.manager.dispatch('getCommentStats', xid);
			this.refreshToken = this.refreshToken + 1;
			this.closeModal();
		}
	}
};
</script>
