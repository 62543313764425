var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "markdown-file--component-wrapper",
      class: { isMarkdownFileSourceLoading: "centered_content" },
    },
    [
      _c("loading", {
        attrs: { active: _vm.isMarkdownFileSourceLoading },
        on: {
          "update:active": function ($event) {
            _vm.isMarkdownFileSourceLoading = $event
          },
        },
      }),
      !_vm.isMarkdownFileSourceLoading
        ? _c(
            "div",
            [
              _vm.$has(_vm.markdownContent)
                ? _c("VueMarkdown", { attrs: { source: _vm.markdownContent } })
                : _c(
                    "b-container",
                    {
                      staticClass: "fallback-container",
                      staticStyle: { "padding-top": "200px" },
                    },
                    [
                      _c(
                        "b-alert",
                        {
                          staticClass: "push_b_40",
                          attrs: { variant: "info", show: "" },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.FormMSG(
                                  1,
                                  "something seem wrong with this file"
                                )
                              ) +
                              "\n        "
                          ),
                        ]
                      ),
                      _c(
                        "b-button",
                        {
                          attrs: { block: "", variant: "success" },
                          on: { click: _vm.downloadFile },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.FormMSG(3, "Download")) +
                              "\n        "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }