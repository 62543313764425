var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "li",
    {
      staticClass:
        "comment-feed-item image_comment_list_item_wrapper_component",
    },
    [
      _c("div", { staticClass: "block" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "lazy-container",
                rawName: "v-lazy-container",
                value: { selector: "img" },
                expression: "{ selector: 'img' }",
              },
            ],
            staticClass: "side_avatar",
          },
          [
            _c("img", {
              staticClass: "img-circle-comment",
              attrs: {
                "data-src": _vm.rendSrcImg,
                alt: _vm.rendAltImg,
                "data-error": "img/brand/avatar.jpg",
                "data-loading": "img/brand/tenor.gif",
              },
            }),
          ]
        ),
        _c("div", { staticClass: "side_content" }, [
          _c("div", { staticClass: "block_content-header" }, [
            _c("div", { staticClass: "header-content" }, [
              _c("div", { staticClass: "header-content--left" }, [
                _c("span", { staticClass: "user-name" }, [
                  _vm._v(
                    "\n\t\t\t\t\t\t\t" +
                      _vm._s(_vm.comment.firstName) +
                      "\n\t\t\t\t\t\t"
                  ),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "header-content--right" },
                [
                  _c(
                    "span",
                    {
                      staticClass: "metadata",
                      staticStyle: { "margin-right": "10px" },
                    },
                    [
                      _c("span", { staticClass: "timestamp" }, [
                        _c("small", [_vm._v(_vm._s(_vm.dateToLiteral))]),
                      ]),
                    ]
                  ),
                  _c(
                    "b-dropdown",
                    {
                      attrs: {
                        size: "lg",
                        variant: "link",
                        "toggle-class": "text-decoration-none",
                        "no-caret": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "button-content",
                          fn: function () {
                            return [_c("i", { staticClass: "icon-arrow-down" })]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      _c(
                        "b-dropdown-item",
                        { on: { click: _vm.handleDelComment } },
                        [
                          _c("span", { staticClass: "text-danger" }, [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t\t\t" +
                                _vm._s(_vm.FormMSG(6879089, "Delete comment")) +
                                "\n\t\t\t\t\t\t\t\t"
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
          _c("div", { staticClass: "block_content-body" }, [
            _vm.comment.pinInfoCode !== 0
              ? _c(
                  "span",
                  {
                    staticClass: "pin-indication",
                    on: {
                      mouseover: function ($event) {
                        return _vm.handPinLabelOrder(true)
                      },
                      mouseleave: function ($event) {
                        return _vm.handPinLabelOrder(false)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(_vm.comment.pinInfoCode) +
                        "\n\t\t\t\t"
                    ),
                  ]
                )
              : _vm._e(),
            _c("span", { domProps: { innerHTML: _vm._s(_vm.rendMessage) } }),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }