import { isNil } from '@/shared/utils';
import languageMessages from '@/mixins/languageMessages';

export default {
	name: 'StatusMixin',
	mixins: [languageMessages],
	methods: {
		/**
		 * @param {String|Number} value
		 * @return {String}
		 */
		rendDocumentValidatedStatus(value) {
			if (isNil(value)) return '...';

			return this.GetTextFromMenuNumberAndMenuValue(1012, value);
		}
	}
};
