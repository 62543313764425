var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "comment-composer--component-wrapper" }, [
    _c("div", { staticClass: "avatar-holder" }, [
      _c("img", {
        attrs: {
          src: _vm.imageName,
          "data-error": "img/brand/avatar.jpg",
          "data-loading": "img/brand/tenor.gif",
        },
      }),
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "click-outside",
            rawName: "v-click-outside",
            value: _vm.handleClickOutside,
            expression: "handleClickOutside",
          },
        ],
        staticClass: "comment-composer-editor",
        on: {
          click: function ($event) {
            $event.stopPropagation()
            $event.preventDefault()
            return _vm.handleCommentComposerFocus.apply(null, arguments)
          },
        },
      },
      [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isCommentComposerFocus,
                expression: "!isCommentComposerFocus",
              },
            ],
            staticClass: "comment-composer-editor-quickAddButtons",
          },
          [
            _c(
              "button",
              {
                staticClass: "option-icon-holder",
                class: {
                  "is-active": _vm.manager.states.isAnnotationToolActive,
                },
                attrs: { id: "tooltip-target-1" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.handleAddAnnotation.apply(null, arguments)
                  },
                },
              },
              [_c("TargetPinIcon")],
              1
            ),
            _c(
              "b-tooltip",
              { attrs: { target: "tooltip-target-1", triggers: "hover" } },
              [
                _vm._v(
                  "\n\t\t\t\t" +
                    _vm._s(_vm.FormMSG(8632784, "Set a comment on a zone")) +
                    "\n\t\t\t"
                ),
              ]
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "comment-composer-editor-textEditor",
            class: { "is-focused": _vm.isCommentComposerFocus },
          },
          [
            !_vm.isCommentComposerFocus
              ? _c("div", { staticClass: "placeholder-wrapper-element" }, [
                  _c(
                    "div",
                    {
                      staticClass: "placeholder-element",
                      class: { "has-payload-message": _vm.hasPayloadMassage },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t" +
                          _vm._s(
                            _vm.hasPayloadMassage
                              ? _vm.commentPayload.message
                              : _vm.FormMSG(
                                  23123,
                                  "Ask a question or post an update"
                                )
                          ) +
                          "\n\t\t\t\t"
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
            _c("b-form-textarea", {
              ref: "commentComposerTextArea",
              staticClass: "comment-input",
              style: _vm.inputStyle,
              attrs: {
                autofocus: "",
                placeholder: `${_vm.FormMSG(
                  7865,
                  "Ex: I have a bad feeling about this"
                )}…`,
                rows: "3",
              },
              model: {
                value: _vm.commentPayload.message,
                callback: function ($$v) {
                  _vm.$set(_vm.commentPayload, "message", $$v)
                },
                expression: "commentPayload.message",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "comment-composer-editor-toolbar",
            class: { "is-visible": _vm.isCommentComposerFocus },
          },
          [
            _c(
              "div",
              { staticClass: "comment-composer-editor-toolbar--left" },
              [
                _c(
                  "button",
                  {
                    staticClass: "option-icon-holder",
                    class: {
                      "is-active": _vm.manager.states.isAnnotationToolActive,
                    },
                    attrs: { id: "tooltip-target-2" },
                    on: { click: _vm.handleAddAnnotation },
                  },
                  [_c("TargetPinIcon")],
                  1
                ),
                _c(
                  "b-tooltip",
                  { attrs: { target: "tooltip-target-2", triggers: "hover" } },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(
                          _vm.FormMSG(8632784, "Set a comment on a zone")
                        ) +
                        "\n\t\t\t\t"
                    ),
                  ]
                ),
                _c(
                  "b-tooltip",
                  { attrs: { target: "tooltip-target-3", triggers: "hover" } },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(
                          _vm.FormMSG(86326578, "Attach a file to this comment")
                        ) +
                        "\n\t\t\t\t"
                    ),
                  ]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "comment-composer-editor-toolbar--right" },
              [
                _c(
                  "b-button",
                  {
                    attrs: {
                      variant: "primary",
                      disabled: _vm.isAddCommentDisabled,
                    },
                    on: { click: _vm.handleAddComment },
                  },
                  [
                    _c("b-spinner", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.manager.states.isCommentSendingLoading,
                          expression: "manager.states.isCommentSendingLoading",
                        },
                      ],
                      attrs: { small: "" },
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.manager.states.isCommentSendingLoading,
                            expression:
                              "!manager.states.isCommentSendingLoading",
                          },
                        ],
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(90876, "Commenter")) +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }