<template>
	<div
		class="account-notifications dropdown"
		:style="`${$screen.width <= 576 ? '' : 'margin-right: 25px;'}`"
		v-if="(totalNotification > 0 && $screen.width > 576) || (totalNotification - totalDocToSign > 0 && $screen.width <= 576)"
	>
		<b-dropdown
			variant="link"
			size="md"
			:class="`notifications-dropdown${$screen.width <= 576 ? ' fix-notifications-for-pwa' : ''}${$isPwa() ? ' pwa' : ''}`"
			toggle-class="text-decoration-none"
			style="width: 30px"
			right
			no-caret
		>
			<template v-slot:button-content>
				<bell :size="18" />
				<b-badge pill class="badge-header badge-variant">{{ $screen.width > 576 ? totalNotification : totalNotification - totalDocToSign }}</b-badge>
			</template>
			<b-dropdown-header id="dropdown-header-label">
				{{ FormMSG(10, 'Your notifications') }}
			</b-dropdown-header>
			<b-dropdown-item size="sm" action to="/mydocuments" v-if="totalDocToRead > 0">
				{{ FormMSG(11, 'New Documents') }} <b-badge pil class="badge-variant">{{ totalDocToRead }}</b-badge></b-dropdown-item
			>
			<!-- project/users -->
			<b-dropdown-item
				size="sm"
				action
				:to="`${isDocManager ? '/project/users' : '/profile?forDocs=true'}`"
				v-if="totalDocToSign > 0 && $screen.width > 576"
			>
				{{ FormMSG(22, 'Documents to validate/sign') }} <b-badge pil class="badge-variant">{{ totalDocToSign }}</b-badge></b-dropdown-item
			>
			<b-dropdown-item action to="/myaccomodations" v-if="totalAccToRead > 0">
				{{ FormMSG(12, 'New accomodations') }} <b-badge pill class="badge-variant">{{ totalAccToRead }}</b-badge>
			</b-dropdown-item>
			<b-dropdown-item action to="/mytimesheets" v-if="totalTsToValidate > 0">
				{{ FormMSG(13, 'Timesheets to validate') }} <b-badge pill class="badge-variant">{{ totalTsToValidate }}</b-badge>
			</b-dropdown-item>
			<b-dropdown-item action to="/myexpenses" v-if="totalExpToValidate > 0">
				{{ FormMSG(15, 'Expenses to validate') }} <b-badge pill class="badge-variant">{{ totalExpToValidate }}</b-badge>
			</b-dropdown-item>
			<b-dropdown-item action to="/mypo" v-if="totalPoToValidate > 0">
				{{ FormMSG(14, 'Purchase orders to validate') }} <b-badge pill class="badge-variant">{{ totalPoToValidate }}</b-badge>
			</b-dropdown-item>
			<b-dropdown-item action to="/mytimesheets" v-if="totalTsRefused > 0">
				{{ FormMSG(16, 'Timesheets refused') }} <b-badge pill class="badge-variant">{{ totalTsRefused }}</b-badge>
			</b-dropdown-item>
			<b-dropdown-item action to="/myexpenses" v-if="totalExpRefused > 0">
				{{ FormMSG(17, 'Expenses refused') }} <b-badge pill class="badge-variant">{{ totalExpRefused }}</b-badge>
			</b-dropdown-item>
			<b-dropdown-item action to="/mypo" v-if="totalPoRefused > 0">
				{{ FormMSG(18, 'Purchase orders refused') }} <b-badge pill class="badge-variant">{{ totalPoRefused }}</b-badge>
			</b-dropdown-item>
			<b-dropdown-item action to="/myexpenses" v-if="totalExpItemRefused > 0">
				{{ FormMSG(19, 'Expense items refused') }} <b-badge pill class="badge-variant">{{ totalExpItemRefused }}</b-badge>
			</b-dropdown-item>
		</b-dropdown>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import languageMessages from '@/mixins/languageMessages';
import { Bell } from 'lucide-vue';
import globalMixin from '@/mixins/global.mixin';
import { getValidations } from '@/cruds/validations.crud';
import { store } from '@/store';

export default {
	name: 'HeaderNotifications',
	components: { Bell },
	mixins: [languageMessages, globalMixin],
	props: {
		appVersion: {
			type: String,
			required: true
		}
	},
	data() {
		return { erreur: {}, myerr: '' };
	},
	computed: {
		...mapGetters({
			totalNotification: 'header/getTotalNotification',
			totalDocToRead: 'header/getTotalDocToRead',
			totalDocToSign: 'header/getTotalDocToSign',
			totalAccToRead: 'header/getTotalAccToRead',
			totalTsToValidate: 'header/getTotalTsToValidate',
			totalPoToValidate: 'header/getTotalPoToValidate',
			totalExpToValidate: 'header/getTotalExpToValidate',
			totalTsRefused: 'header/getTotalTsRefused',
			totalPoRefused: 'header/getTotalPoRefused',
			totalExpRefused: 'header/getTotalExpRefused',
			totalExpItemRefused: 'header/getTotalExpItemRefused'
			// count: 'header/getCount',
		}),
		isDocManager() {
			if (
				(store.state.myProfile.upm ||
					store.state.myProfile.gateKeeper ||
					store.state.myProfile.productionAccountant ||
					store.state.myProfile.lineProducer ||
					store.state.myProfile.studio ||
					store.state.myProfile.canValidateI9) &&
				store.getCurrentProjectConfig().useDocument
			) {
				return true;
			}

			return false;
		}
	},
	async created() {
		await this.getNotifications().catch((error) => {
			console.error({ error });
		});
	},
	methods: {
		...mapActions({
			getNotifications: 'header/getNotifications'
		}),
		handleClick(screen) {
			if (this.$screen.width <= 576) {
				this.createToastForMobile(
					this.FormMSG(20, 'Not allowed'),
					this.FormMSG(
						21,
						"It's currrently not possible to do approval from a mobile device. To access the approval screens, please use a computer."
					),
					'',
					'danger'
				);
			} else {
				this.$router.push(`/${screen}/?validate=true`);
			}
		}
	}
};
</script>

<style lang="scss">
.fix-notifications-for-pwa {
	margin-left: -2px;
	margin-right: 16px;
	&.show {
		&.pwa .dropdown-menu {
			top: 80px !important;
		}
	}
}

.notifications-dropdown button {
	padding: 0;
}

.badge-header {
	position: absolute !important;
	margin-top: -2px;
	font-size: 77.5%;
}

.badge-variant {
	color: #ea4e2c;
	letter-spacing: 0.03rem;
	background-color: #fcede9;
}
</style>
