var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "other-file-viwer--filepreviewer--component-wrapper" },
    [
      _c("span", { staticClass: "text-info" }, [
        _vm._v(
          _vm._s(
            _vm.FormMSG(
              6,
              "This file extention is not readable here you can open it here"
            )
          )
        ),
      ]),
      _c(
        "b-button",
        {
          attrs: { variant: "success btn-block" },
          on: { click: _vm.handleOpenFile },
        },
        [_vm._v("\n\t\t" + _vm._s(_vm.FormMSG(33, "Open file")) + "\n\t")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }