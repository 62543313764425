<template>
	<li ref="dropdowncoruiItem" class="nav-item nav-dropdown" :class="open ? 'open' : ''">
		<div class="nav-link nav-dropdown-toggle" :class="noTranslate()" @click="handleClick"><i :class="classIcon"></i> {{ item.name }}</div>
		<ul class="nav-dropdown-items">
			<slot></slot>
		</ul>
	</li>
</template>

<script>
import { SidebarNavDropdown } from '@coreui/vue';
import { store } from '@/store';
import { mapActions } from 'vuex';

export default {
	extends: SidebarNavDropdown,
	props: {
		index: {
			type: Number,
			required: true
		},
		url: {
			type: String,
			required: true,
			default: () => ''
		},
		open: {
			type: Boolean,
			required: true
		},
		item: {
			type: Object,
			required: true,
			default: () => {}
		}
	},
	// watch: {
	// 	open(status) {
	// 		const action = status ? 'add' : 'remove';
	// 		this.$refs.dropdowncoruiItem.classList[action]('open');
	// 		// this.$refs.dropdowncoruiItem.$el.classList[action]('open');
	// 	}
	// },
	// mounted() {
	// 	this.initDefaultOpenStat();
	// },
	methods: {
		...mapActions({
			mutateNavItems: 'nav-items/mutateNavItems'
		}),
		// initDefaultOpenStat() {
		// 	if (!this.open) {
		// 		this.$refs.dropdowncoruiItem.classList.remove('open');
		// 		// this.$refs.dropdowncoruiItem.$el.classList.remove('open');
		// 	}
		// },
		async handleClick(e) {
			// this.$emit('drop-state-change', this.item);

			// save menuItems state change
			await this.mutateNavItems({ isUpdate: true, index: this.index });

			// activate the dropdown action
			/** No need anymore, the store will do that */
			// e.preventDefault();
			// e.target.parentElement.classList.toggle('open');
		},
		noTranslate() {
			return this.item.msgId && this.item.msgId === 41 ? 'notranslate' : '';
		}
	}
};
</script>
