var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "meta-data-headbar--imageshowroom" }, [
    _c("div", { staticClass: "maetadata-title-block full" }),
    _c("div", { staticClass: "block--right content_sb_middle_end" }, [
      _vm.$has("actions")
        ? _c(
            "div",
            { staticClass: "maetadata-actions-block" },
            [_vm._t("actions")],
            2
          )
        : _vm._e(),
      _c("div", { staticClass: "maetadata-close-block" }, [
        _c(
          "button",
          {
            ref: "documentViewerModalClose",
            staticClass: "btn bg-transparent border-0",
            attrs: { type: "button" },
            on: { click: _vm.handleCloseShowRoom },
          },
          [
            _c(_vm.getLucideIcon("X"), {
              tag: "component",
              attrs: { color: "#5B6164D6", size: 20, "stroke-width": 2.5 },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }