<template>
	<div class="meta-data-headbar--imageshowroom">
		<div class="maetadata-title-block full">
			<!-- <span class="image-name">Image name</span>
      <span class="image-date">Date</span> -->
		</div>
		<div class="block--right content_sb_middle_end">
			<div v-if="$has('actions')" class="maetadata-actions-block">
				<slot name="actions" />
			</div>
			<div class="maetadata-close-block">
				<button class="btn bg-transparent border-0" type="button" ref="documentViewerModalClose" @click="handleCloseShowRoom">
					<!-- <i class="icon-plus" /> -->
					<component :is="getLucideIcon('X')" color="#5B6164D6" :size="20" :stroke-width="2.5" />
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import GlobalMixin from '@/mixins/global.mixin';
export default {
	name: 'MetaDataHeadBarImageShowRoomComponent',
	mixins: [GlobalMixin],
	methods: {
		handleCloseShowRoom() {
			this.$emit('close', true);
		}
	}
};
</script>

<style lang="scss" scoped>
.meta-data-headbar--imageshowroom {
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
	// align-items: center;
	border-bottom: 0.005rem solid rgba(34, 92, 189, 0.2);
	// display: flex;
	flex: 0 0 auto;
	width: 100%;
	height: 61px;
	padding-left: 15px;
	background-color: hsla(0, 0%, 100%, 0.9);
	-webkit-backdrop-filter: saturate(180%) blur(15px);
	backdrop-filter: saturate(180%) blur(15px);
	.maetadata-title-block {
		span {
			display: inline-block;
			width: 100%;
		}
	}
	.maetadata-actions-block {
		display: flex;
		justify-content: flex-end;
		padding-right: 20px;
		width: 100%;
		*:not(:last-child) {
			margin-right: 0.5rem;
			width: 100%;
		}
	}
	.maetadata-close-block {
		border-left: 0.005rem solid rgba(34, 92, 189, 0.2);
		height: 61px;
		display: flex;
		align-items: center;
		width: 60px;
		justify-content: center;
		button.btn-close-imageshowroom {
			background-color: transparent;
			border: none;
			transform: rotate(45deg);
			i {
				font-size: 18px;
			}
		}
	}
}
</style>
