<template>
	<div :ref="`stats_img__${xid}`" class="">
		<b-button-group v-if="!manager.states.options.hideLikeDislike" size="sm" class="">
			<b-button class="btn bg-transparent border-0" :disabled="isCommentStatsLoading" @click="handleAddLike">
				<b-spinner v-if="isCommentStatsLoading" small style="margin-right: 15px" />
				<div class="d-flex align-items-center" style="color: rgba(6, 38, 62, 0.84)" v-else>
					<component :is="getLucideIcon('ThumbsUp')" color="#5B6164D6" :size="18" :stroke-width="2.5" />
					<div style="font-size: 15px; color: rgba(6, 38, 62, 0.64); margin-top: 4px" class="ml-1" v-if="commentStats.likes.length > 0">
						{{ commentStats.likes }}
					</div>
				</div>
			</b-button>
			<b-button class="btn bg-transparent border-0" :disabled="isCommentStatsLoading" @click="handleAddDisLike">
				<b-spinner v-if="isCommentStatsLoading" small style="margin-right: 15px" />
				<div class="d-flex align-items-center" style="color: rgba(6, 38, 62, 0.84)" v-else>
					<component :is="getLucideIcon('ThumbsDown')" color="#5B6164D6" :size="18" :stroke-width="2.5" />
					<div style="font-size: 15px; color: rgba(6, 38, 62, 0.64); margin-top: 4px" class="ml-1" v-if="commentStats.disLikes.length > 0">
						{{ commentStats.disLikes }}
					</div>
				</div>
			</b-button>
			<b-button
				class="btn bg-transparent border-0"
				:disabled="isCommentStatsLoading"
				v-b-tooltip.hover.right
				:title="commentBtnText"
				@click="handleLoadCommentList"
			>
				<!-- {{ commentStats.comments }} -->
				<div class="d-flex align-items-center" style="color: rgba(6, 38, 62, 0.84)">
					<!-- {{ commentBtnText }} -->
					<component :is="getLucideIcon('MessageCircle')" color="#5B6164D6" :size="18" :stroke-width="2.5" />
				</div>
			</b-button>
		</b-button-group>
		<b-button v-else class="btn bg-transparent border-0" :disabled="isCommentStatsLoading" @click="handleLoadCommentList">
			<b-spinner v-show="isCommentStatsLoading" small style="margin-right: 15px" />
			<!-- {{ commentStats.comments }} -->
			<div class="d-flex align-items-center" style="color: rgba(6, 38, 62, 0.84)">
				<!-- {{ commentBtnText }} -->
				<component :is="getLucideIcon('MessageCircle')" color="#5B6164D6" :size="18" :stroke-width="2.5" />
			</div>
		</b-button>
	</div>
</template>

<script>
import { mapFilters } from '~filters';
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';

export default {
	name: 'ImageCommentStatsButtonComponent',
	mixins: [languageMessages, globalMixin],
	props: {
		manager: {
			type: Object,
			required: true,
			default: () => {}
		}
	},
	computed: {
		/**
		 * @return {String}
		 */
		xid() {
			return this.manager.states.currentActiveSlide;
		},
		/**
		 * @return {Boolean}
		 */
		isCommentStatsLoading() {
			return this.manager.states.isCommentStatsLoading;
		},
		/**
		 * @return {Boolean}
		 */
		commentStats() {
			return this.manager.states.commentStats;
		},
		isCommentPanelOpen: {
			/**
			 * @return {Boolean}
			 */
			get() {
				return this.manager.states.isCommentPanelOpen;
			},
			/**
			 * @param {Boolean} status
			 */
			set(status) {
				this.manager.dispatch('setCommentPanelOpen', status);
			}
		},
		/**
		 * @return {String}
		 */
		commentBtnText() {
			const t = this.FormMSG(1, 'Comment');
			const count = this.manager.states.commentsList.length;
			return this.pluralize(t, count);
		}
	},
	methods: {
		...mapFilters(['pluralize']),

		handleLoadCommentList() {
			this.isCommentPanelOpen = !this.isCommentPanelOpen;
			this.manager.dispatch('setCurrentActiveSlideStatus', !this.isCommentPanelOpen);
		},

		async handleAddLike() {
			await this.manager.dispatch('addLike');
		},
		async handleAddDisLike() {
			await this.manager.dispatch('addDisLike');
		}
	}
};
</script>
