<template>
	<div class="images-list-thumbnails--imagesshowroom--component-wrapper" :class="rendImagesListThumbnails">
		<div
			v-for="(item, i) in imagesList"
			:key="i"
			class="thumbnail-image"
			:class="{ 'is-active': isActive(item.xid) }"
			@click="handleThumbnailClick(item.xid)"
		>
			<div v-lazy-container="{ selector: 'img' }" class="img-lazy-container" v-if="i === imagesList.length - 1">
				<img :data-src="item.src" data-error="img/thumb-not-found.png" data-loading="img/brand/tenor.gif" class="w-100 h-auto" />
			</div>
			<div v-lazy-container="{ selector: 'img' }" class="img-lazy-container" v-else>
				<img :data-src="rendItemBackgroundImage(item)" data-error="img/thumb-not-found.png" data-loading="img/brand/tenor.gif" class="w-100 h-auto" />
			</div>
		</div>
	</div>
</template>

<script>
import { isNil, getObjectFromValue } from '@/shared/utils';

export default {
	name: 'ImagesListThumbnailsComponent',
	props: {
		manager: {
			type: Object,
			required: true,
			default: () => {}
		}
	},
	computed: {
		/**
		 * @return {Array}
		 */
		imagesList() {
			return this.manager.states.imagesList;
		},
		/**
		 * @return {String}
		 */
		currentActiveSlide() {
			return this.manager.states.currentActiveSlide;
		},

		/**
		 * @return {Array}
		 */
		rendImagesListThumbnails() {
			return {
				'vertical-mode': this.manager.states.options.presentationMode !== 'image'
			};
		}
	},
	methods: {
		/**
		 * @param {String} xid
		 */
		handleThumbnailClick(xid) {
			const newActive = getObjectFromValue(this.imagesList, 'xid', xid);
			this.manager.dispatch('setCurrentActiveSlide', newActive.xid);
		},
		/**
		 * @param {String} xid
		 * @return {Boolean}
		 */
		isActive(xid) {
			return this.currentActiveSlide === xid;
		},

		/**
		 * @param {Object} item
		 * @return {String}
		 */
		rendItemBackgroundImage(item) {
			if (isNil(item)) return '';
			const imgSrc = isNil(item.thumb) ? item.src : item.thumb;
			// TODO: add default thumb or src if 404 file not found
			return imgSrc;
		}
	}
};
</script>

<style lang="scss" scoped>
@import './../../../../assets/scss/tools/mixins';
@import './../../../../assets/scss/tools/media-queries';

.images-list-thumbnails--imagesshowroom--component-wrapper {
	overflow-y: scroll;
	overflow-x: auto;

	align-self: center;
	display: flex;
	flex: 0 0 auto;
	flex-wrap: wrap;
	justify-content: flex-start;
	margin: 0 48px;
	.thumbnail-image {
		align-items: center;
		background-color: #1e1f21;
		background-position: 50% 50%;
		background-repeat: no-repeat;
		background-size: cover;
		border: 2px solid transparent;
		border-radius: 4px;
		cursor: pointer;
		display: inline-flex;
		justify-content: center;
		margin-bottom: 24px;
		margin-left: 8px;
		margin-right: 8px;
		opacity: 0.5;
		position: relative;
		transition-property: opacity;
		@include size(auto, 40px);
		object-fit: contain;
		&.is-active {
			opacity: 1;
		}

		& > .img-lazy-container {
			max-width: 100%;
			height: 100%;
			& > img {
				max-width: 100%;
				max-height: 100%;
				object-fit: contain;
			}
		}

		& > img {
			height: 100%;
		}
	}

	&,
	&.vertical-mode {
		.thumbnail-image {
			@include size(160px, auto);
			& > img {
				@include size(f, auto);
			}

			@include max-screen(600px) {
				&,
				& > img {
					@include size(auto, 100%);
					margin-bottom: 0;
				}
			}
		}
	}
}
</style>
