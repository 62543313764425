<template>
	<div class="pins-holder" :class="{ 'has-pin-hovered': $has(manager.states.currPinHovered) }">
		<span
			v-for="(pin, i) in positionedPins"
			:key="i"
			:ref="rendPinRef(pin.xid)"
			class="positioned-pin"
			:class="{ 'is-currently-hovered': manager.states.currPinHovered === pin.pinInfoCode }"
			:style="rendPinStyle(pin)"
		>
			{{ pin.pinInfoCode }}
		</span>
	</div>
</template>

<script>
import mapProps from '@/shared/vuePropsMapper';
import { isNil } from '@/shared/utils';
import { buildViewPos } from '@/shared/positionsMaker';

export default {
	name: 'PositionedPinsFIlesPreviewerComponent',
	props: {
		...mapProps(['dropzoneRef', 'imageRef'], {
			type: String,
			required: true
		}),
		manager: {
			type: Object,
			required: true,
			default: () => {}
		},
		xid: {
			type: [String, Number],
			required: true
		}
	},
	computed: {
		/**
		 * @return {Array}
		 */
		positionedPins() {
			return this.manager.states.pinsList;
		},

		/**
		 * @return {Boolean}
		 */
		hasPinHovered() {
			return !isNil(this.manager.states.currPinHovered);
		}
	},
	methods: {
		/**
		 * @param {Object} pin
		 * @return {Object}
		 */
		rendPinStyle(pin) {
			const payload = buildViewPos(
				{
					coord_x: pin.xPos,
					coord_y: pin.yPos
				},
				this.$parent.$refs[this.dropzoneRef],
				this.$parent.$refs[this.imageRef]
			);
			return {
				left: `${payload.x}px`,
				top: `${payload.y}px`
			};
		},

		/**
		 * @param {String} xid
		 * @return {String}
		 */
		rendPinRef(xid) {
			return `pin-ref-${xid}`;
		}

		// /**
		//  * @param {Object} pin
		//  * @return {Object}
		//  */
		// rendPinStyle(pin) {
		// 	return {
		// 		left: 0,
		// 		right: 0
		// 	}
		// }
	}
};
</script>

<style lang="scss" scoped>
.pins-holder {
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 1;

	.positioned-pin {
		position: absolute;
		margin: auto;
		background-color: aqua;
		border-radius: 50px;
		width: 30px;
		height: 30px;
		text-align: center;
		line-height: 30px;
		transform: translate(-50%, -50%) scale(1);
		opacity: 1;
		transform-origin: center center;
		transition: opacity ease-in-out 100ms;
		&.is-currently-hovered {
			transform: translate(-50%, -50%) scale(1.3);
		}
	}
}
</style>
