import { isNil, isObj } from '@/shared/utils';

/**
 * Placement only for view size
 *
 * @param {Object} positionObject
 * @param {Element} area
 * @param {Element} img
 * @return {Object}
 */
const buildPayloadPlacement = ({ offsetX: x, offsetY: y }, area, img) => {
	if (isNil(area) || isNil(img) || isNil(x) || isNil(y)) return {};
	const { offsetLeft: imgOL, offsetTop: imgOT, offsetWidth: imgOH, offsetHeight: imgOW } = img;
	const offsetXLessLeft = x - imgOL;
	const offsetYLessTop = y - imgOT;

	const _x = (offsetXLessLeft / imgOW) * 1000;
	const _y = (offsetYLessTop / imgOH) * 500;

	return {
		coord_x: parseInt(_x * 1000),
		coord_y: parseInt(_y * 1000)
	};
};

/**
 * @param {Array} arr
 * @return {Object}
 */
const normalizeArray = (arr) => (Array.isArray(arr) && !isObj(arr) ? arr[0] : arr);

// const getRenderedSize = (contains, cWidth, cHeight, width, height, pos) => {
// 	var oRatio = width / height,
// 		cRatio = cWidth / cHeight
// 	return function() {
// 		if (contains ? oRatio > cRatio : oRatio < cRatio) {
// 			this.width = cWidth
// 			this.height = cWidth / oRatio
// 		} else {
// 			this.width = cHeight * oRatio
// 			this.height = cHeight
// 		}
// 		this.left = (cWidth - this.width) * (pos / 100)
// 		this.right = this.width + this.left
// 		return this
// 	}.call({})
// }

// const getImgSizeInfo = img => {
// 	var pos = window
// 		.getComputedStyle(img)
// 		.getPropertyValue('object-position')
// 		.split(' ')
// 	return getRenderedSize(true, img.width, img.height, img.naturalWidth, img.naturalHeight, parseInt(pos[0]))
// }

/**
 * @param {Object} dragData
 * @param {Element} area
 * @param {Element} img
 * @return {Object}
 */
export const getDropData = (dragData, area, img) => {
	if ((isNil(area), isNil(img))) return;

	const areaEl = Array.isArray(area) ? area[0] : area;
	const imgEl = Array.isArray(img) ? img[0] : img;
	const { offsetX, offsetY } = dragData;

	const payload = buildPayloadPlacement({ offsetX, offsetY }, areaEl, imgEl);

	return {
		payload,
		view: { x: offsetX, y: offsetY }
	};
};

/**
 * This helper is used for rend pins on the viewer plan on the loading
 *
 * @param {Object} positionObject
 * @param {Element} area
 * @param {Element} img
 * @return  {Object}
 */
export const buildViewPos = ({ coord_x: x, coord_y: y }, area, img) => {
	if (isNil(area) || isNil(img) || isNil(x) || isNil(y)) return {};

	// const dropArea = normalizeArray(area)
	const focusImg = normalizeArray(img);

	// const { offsetHeight: areaOH, offsetWidth: areaOW } = dropArea
	const { offsetLeft: imgOL, offsetWidth: oH, offsetHeight: oW, offsetTop: imgOT } = focusImg; // offsetWidth: oH, offsetHeight: oW, offsetTop: imgOT
	// const { width: oH, height: oW } = getImgSizeInfo(focusImg)
	// const imgOT = (areaOH - oW) / 2

	const _x = (x / 1000) * oW;
	const _y = (y / 500) * oH;

	return {
		x: _x / 1000 + imgOL,
		y: _y / 1000 + imgOT
	};
};
