<template>
	<div class="sign-document-file-previewer--component-wrapper">
		<div
			v-if="manager.states.options.showStatusFileTag && $has(currFileDetails.status)"
			class="wrap-status mr-3"
			:style="{
				'text-align':
					(!manager.states.options.hideCropButton && !manager.states.options.hideDeleteButton) ||
					(!manager.states.options.hideLikeDislike && !manager.states.options.hideCommentButton && !manager.states.options.hideDisLikeButton)
						? 'unset'
						: 'center'
			}"
		>
			<div class="wrap-status" :class="currFileDetails.validatedClass">
				<div :class="`status ${getStatusClass(currFileDetails.status)}`">
					{{ rendDocumentValidatedStatus(currFileDetails.status) }}
				</div>
			</div>
		</div>
		<b-button
			size="sm"
			class="btn bg-transparent border-0"
			v-if="isFileSignable"
			:disabled="isStatusLoading"
			@click="confirmPasswordPrompt"
			v-b-tooltip.hover.left
			:title="FormMSG(12321, 'Sign')"
		>
			<b-spinner v-if="isStatusLoading" small style="margin-right: 15px" />
			<component :is="getLucideIcon('FileSignature')" :color="ICONS.EDIT.color" :size="20" v-else />
		</b-button>
		<oodrive-sign-contract-modal
			id="oodrive-sign-contract-modal"
			v-model="isOodriveSignContractOpen"
			:url="urlOodrive"
			:delivery-id="documentSelected ? documentSelected.firstDeliveryId : 0"
			:doc-signed-xid="documentSelected ? documentSelected.docSignedXid : ''"
			@oodrive-sign-contract-modal:close="onOodriveSignContractClosed"
			@oodrive-sign-contract-modal:documentUpdateStatus="onOodriveSignContractStatusUpdated"
		/>
	</div>
</template>

<script>
//  badge-${data.item.validatedClass}
// import gql from 'graphql-tag';
import { isNil } from '@/shared/utils';
import { store } from '@/store/index';
import { signDocument, getDocument, getOodriveSignatureLink } from '@/cruds/document.crud';
import statusMixin from '@/mixins/status.mixin';
import languageMessages from '@/mixins/languageMessages';
import { classDocumentStatus } from '@/shared/global-status';
import OodriveSignContractModal from '@/components/Modals/OodriveSignContractModal';
import { OODRIVE_VALID_DOCUMENT_EXT } from '@/shared/constants';
import globalMixin from '@/mixins/global.mixin';
const signableFilesExt = ['pdf', 'xlsx', 'docx'];

export default {
	name: 'SignDocumentToolComponent',
	mixins: [statusMixin, languageMessages, globalMixin],
	components: {
		OodriveSignContractModal
	},
	props: {
		manager: {
			type: Object,
			required: true,
			default: () => {}
		}
	},
	data() {
		return {
			isStatusLoading: false,
			isDocumentSignLoading: false,
			isCurrFileCanBeSign: true,
			documentSelected: null,
			requesterId: 0,
			userId: 0,
			isOodriveSignContractOpen: false,
			urlOodrive: ''
		};
	},
	created() {
		this.$bus.$on('filePreviewer:sign-successfully-done', (docXid) => {
			if (isNil(docXid) || this.xid !== docXid) return;
			this.isCurrFileCanBeSign = false;
		});
	},
	computed: {
		isFileSignable() {
			const isDoc = this.manager.states.options.presentationMode === 'doc';
			const ext = this.manager.states.currentActiveSlideExt;
			const optCheck = this.manager.states.options.showSignFileButton;
			const toSign = this.currFileDetails.toSign || false;
			const hasGoodStatus = this.currFileDetails.status === 3 || false;

			return isDoc && signableFilesExt.includes(ext) && optCheck && toSign && this.isCurrFileCanBeSign && hasGoodStatus;
		},
		/**
		 * @return {String}
		 */
		xid() {
			return this.manager.states.currentActiveSlide;
		},
		/**
		 * @return {Object}
		 */
		currFileDetails() {
			return this.manager.states.currFileDetails;
		}
	},
	methods: {
		getStatusClass(status) {
			return classDocumentStatus(status);
		},
		handleModalForSignDocumentDocumentSigned() {
			this.manager.states.currFileDetails.status = 4;
			this.isCurrFileCanBeSign = false;
		},
		async handleSignDocument() {
			const docId = this.currFileDetails.id;
			const userId = await store.userID();
			this.isDocumentSignLoading = true;
			try {
				await signDocument({ docId, userId });
				this.manager.stream.$emit('file-signed-successfully', true);
				this.createToastForMobile(
					this.FormMSG(3213214, 'Document signed'),
					this.FormMSG(32123, 'Your document has been successfully signed'),
					'',
					'success'
				);
			} catch (err) {
				this.createToastForMobile(this.FormMSG(368, 'Error'), err.message, '', 'danger');
			}
			this.isDocumentSignLoading = false;
		},
		async confirmPasswordPrompt() {
			this.documentSelected = this.currFileDetails;
			if (OODRIVE_VALID_DOCUMENT_EXT.includes(this.documentSelected.ext)) {
				this.$emit('sign-document-tool:watingForOodrive', true);
				await getOodriveSignatureLink(this.documentSelected.firstDeliveryId)
					.then((result) => {
						this.$emit('sign-document-tool:watingForOodrive', false);
						this.urlOodrive = result;
						this.isOodriveSignContractOpen = true;
					})
					.catch((error) => {
						console.log({ error });
						this.$emit('sign-document-tool:watingForOodrive', false);
					})
					.finally(() => {
						this.$emit('sign-document-tool:watingForOodrive', false);
					});
			}
		},
		onOodriveSignContractClosed() {
			this.isOodriveSignContractOpen = false;
			this.$emit('sign-document-tool:closeViewer');
		},
		async onOodriveSignContractStatusUpdated(payload) {
			if (!_.isNil(payload)) {
				this.isCurrFileCanBeSign = false;
				this.$emit('sign-document-tool:closeViewer');
				this.$emit('sign-document-tool:documentUpdateStatus', payload);
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.sign-document-file-previewer--component-wrapper {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding-left: 10px;

	&:empty {
		padding-left: 0;
	}
}
</style>
