<template>
	<div class="comment-composer--component-wrapper">
		<div class="avatar-holder">
			<img :src="imageName" data-error="img/brand/avatar.jpg" data-loading="img/brand/tenor.gif" />
		</div>

		<div v-click-outside="handleClickOutside" class="comment-composer-editor" @click.stop.prevent="handleCommentComposerFocus">
			<div v-show="!isCommentComposerFocus" class="comment-composer-editor-quickAddButtons">
				<button
					id="tooltip-target-1"
					class="option-icon-holder"
					:class="{ 'is-active': manager.states.isAnnotationToolActive }"
					@click.stop.prevent="handleAddAnnotation"
				>
					<TargetPinIcon />
				</button>
				<b-tooltip target="tooltip-target-1" triggers="hover">
					{{ FormMSG(8632784, 'Set a comment on a zone') }}
				</b-tooltip>
			</div>
			<div class="comment-composer-editor-textEditor" :class="{ 'is-focused': isCommentComposerFocus }">
				<div v-if="!isCommentComposerFocus" class="placeholder-wrapper-element">
					<div class="placeholder-element" :class="{ 'has-payload-message': hasPayloadMassage }">
						{{ hasPayloadMassage ? commentPayload.message : FormMSG(23123, 'Ask a question or post an update') }}
					</div>
				</div>

				<b-form-textarea
					autofocus
					ref="commentComposerTextArea"
					v-model="commentPayload.message"
					class="comment-input"
					:placeholder="`${FormMSG(7865, 'Ex: I have a bad feeling about this')}…`"
					:style="inputStyle"
					rows="3"
				/>

				<!-- <div
					class="ProsemirrorEditor TextEditor2-prosemirrorEditor ProseMirror"
					tabindex="0"
					contenteditable
					:aria-label="FormMSG(987987, 'Modifier le commentaire')"
				>
					<p><br /></p>
				</div> -->
			</div>
			<div class="comment-composer-editor-toolbar" :class="{ 'is-visible': isCommentComposerFocus }">
				<div class="comment-composer-editor-toolbar--left">
					<button
						id="tooltip-target-2"
						class="option-icon-holder"
						:class="{ 'is-active': manager.states.isAnnotationToolActive }"
						@click="handleAddAnnotation"
					>
						<TargetPinIcon />
					</button>
					<b-tooltip target="tooltip-target-2" triggers="hover">
						{{ FormMSG(8632784, 'Set a comment on a zone') }}
					</b-tooltip>

					<!-- <button
						id="tooltip-target-3"
						class="option-icon-holder"
						disabled
						:class="{ 'is-active': manager.states.isAnnotationToolActive }"
						@click="handleAddAnnotation"
					>
						<AttachfileIcon />
					</button> -->
					<b-tooltip target="tooltip-target-3" triggers="hover">
						{{ FormMSG(86326578, 'Attach a file to this comment') }}
					</b-tooltip>
				</div>
				<div class="comment-composer-editor-toolbar--right">
					<b-button variant="primary" :disabled="isAddCommentDisabled" @click="handleAddComment">
						<b-spinner v-show="manager.states.isCommentSendingLoading" small />
						<span v-show="!manager.states.isCommentSendingLoading">
							{{ FormMSG(90876, 'Commenter') }}
						</span>
					</b-button>
				</div>
			</div>
		</div>
		<!-- <div class="comment-composer-editor-toolbar"></div> -->
	</div>
</template>

<script>
import { isNil } from '@/shared/utils';
const defaultImgName = 'img/brand/avatar.jpg';
import languageMessages from '@/mixins/languageMessages';
import TargetPinIcon from '@/components/icons/target-pin';
// import AttachfileIcon from '@/components/icons/attachfile'

export default {
	name: 'CommentComposerFIlesPreviewerComponent',
	components: {
		TargetPinIcon
		// AttachfileIcon
	},
	mixins: [languageMessages],
	props: {
		manager: {
			type: Object,
			required: true,
			default: () => {}
		}
	},
	data() {
		return {
			baseImgPath: process.env.VUE_APP_GQL,
			inputHeight: 0
		};
	},
	computed: {
		commentPayload: {
			/**
			 * @return {Object}
			 */
			get() {
				return this.manager.states.commentPayload;
			},
			/**
			 * @param {Object} payload
			 */
			set(payload) {
				this.manager.dispatch('setCommentPayload', payload);
			}
		},

		imageName() {
			const _auth = this.$store.state.auth;
			if (isNil(_auth)) return defaultImgName;

			return isNil(_auth.picture) || _auth.picture.length == 0 ? defaultImgName : `${this.baseImgPath}/imagethumb/${_auth.picture}`;
		},

		isCommentComposerFocus: {
			/**
			 * @return {Boolean}
			 */
			get() {
				return this.manager.states.isCommentComposerFocus;
			},
			/**
			 * @param {Boolean} status
			 */
			set(status) {
				this.manager.dispatch('toggleCommentComposerFocusStatus', status);
			}
		},

		/**
		 * @return {Boolean}
		 */
		isAddCommentDisabled() {
			const { message } = this.commentPayload;
			return this.$nil(message) || message === '';
		},

		/**
		 * @return {Object}
		 */
		inputStyle() {
			return {
				'min-height': `${this.inputHeight}px`
			};
		},

		/**
		 * @return {Boolean}
		 */
		hasPayloadMassage() {
			return !isNil(this.commentPayload.message) && this.commentPayload.message !== '';
		}
	},
	watch: {
		'commentPayload.message'() {
			this.commentTextAreaResize();
		},

		/**
		 * @param {Boolean} status
		 */
		isCommentComposerFocus(status) {
			if (!status && !this.hasPayloadMassage) this.inputHeight = 0;
		}
	},
	methods: {
		commentTextAreaResize() {
			this.inputHeight = this.$refs.commentComposerTextArea.$el.scrollHeight;
		},

		handleAddAnnotation() {
			const _c = this.manager.states.isAnnotationToolActive;
			this.manager.dispatch(_c ? 'destroyAnnotationTool' : 'setAnnotationToolActive');
		},

		handleCommentComposerFocus() {
			this.$refs.commentComposerTextArea.focus();
			this.isCommentComposerFocus = true;
		},

		handleClickOutside() {
			this.isCommentComposerFocus = false;
		},

		async handleAddComment() {
			const xid = this.manager.states.currentActiveSlide;
			await this.manager.dispatch('addComment', { xid, ...this.commentPayload, docDeliId: this.manager.states.currFileDetails.firstDeliveryId });
			if (this.manager.states.isAnnotationToolActive) this.manager.dispatch('toggleAnnotationToolActive', false);
			// await this.manager.dispatch('getCommentStats', xid);
			this.inputHeight = 0;
			this.commentPayload = Object.assign({}, { message: null });
			this.isCommentComposerFocus = false;
		}
	}
};
</script>

<style lang="scss" scoped>
.comment-composer--component-wrapper {
	display: flex;
	position: relative;

	.avatar-holder {
		margin-right: 15px;
		width: 32px;
		overflow: hidden;
		height: 32px;
		align-items: center;
		border-radius: 50%;
		box-sizing: border-box;
		color: #fff;
		-webkit-print-color-adjust: exact;
		color-adjust: exact;
		display: inline-flex;
		justify-content: center;
		position: relative;
		vertical-align: top;
		img {
			display: block;
			width: 100%;
		}
	}

	.comment-composer-editor {
		font-size: 14px;
		font-weight: 400;
		line-height: 22px;
		background-color: rgb(239, 232, 232);
		border-width: 1px;
		border-style: solid;
		border-color: #666;
		border-radius: 6px;
		padding: 0 12px;
		box-sizing: border-box;
		flex: 1 1 auto;
		min-width: 1px;
		overflow: hidden;
		padding: 0;
		position: relative;
		cursor: text;
		transition: border-color ease-in-out 300ms;

		&:hover {
			border-color: #333;
		}

		.comment-composer-editor- {
			&toolbar {
				margin-bottom: -53px;
				transition: margin-bottom 250ms;
				align-items: center;
				box-shadow: inset 0 1px #999;
				padding: 8px;
				text-align: right;
				display: flex;
				opacity: 0;
				justify-content: space-between;

				&.is-visible {
					margin-bottom: 0;
					opacity: 1;
				}

				.comment-composer-editor-toolbar-- {
					&left,
					&right {
						display: flex;
						align-items: center;
						justify-content: flex-start;
					}
					&right {
						justify-content: flex-end;
					}
				}
			}
			&quickAddButtons {
				display: flex;
				flex-direction: row;
				margin-right: 8px;
				position: absolute;
				right: 0;
				top: 0;
				z-index: 200;
			}
			&textEditor {
				border: 0;
				font-size: 14px;
				font-weight: 400;
				line-height: 22px;
				overflow: visible;
				// height: 32px;
				transition: height ease-in-out 200ms;

				textarea {
					border: none;
					opacity: 0;
					background: transparent;
				}

				&.is-focused {
					// padding-bottom: 72px;
					textarea {
						opacity: 1;
					}
				}

				.placeholder-wrapper-element {
					margin-right: 84px;
					left: 0;
					padding: inherit;
					pointer-events: none;
					position: absolute;
					right: 0;
					top: 0;
					-webkit-user-select: none;
					-moz-user-select: none;
					-ms-user-select: none;
					user-select: none;

					.placeholder-element {
						padding: 8px 8px;
						font-size: 14px;
						line-height: 22px;
						padding: 4px 8px;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
				}
			}
		}
	}
}

.TextEditorToolbarButton,
.AddAttachmentsButton {
	width: 16px;
}

.AddAttachmentsButton {
	input {
		visibility: hidden;
	}

	.SubtleIconButton {
		width: 16px;
	}
}

.option-icon-holder {
	width: auto;
	background-color: transparent;
	font-size: 12px;
	height: 28px;
	display: flex;
	align-items: center;
	line-height: 28px;
	padding: 0 5px;
	transition: background-color ease-in-out 300ms;
	border-radius: 4px;
	border: none;
	&:not(:last-child) {
		margin-right: 5px;
	}
	&:not(.is-disabled) {
		cursor: pointer;
	}
	&:hover {
		background-color: #f5f5f5;
	}
	&.is-disabled > * {
		opacity: 0.4;
	}
	svg {
		display: block;
		height: 16px;
		width: 16px;
	}
}
</style>
