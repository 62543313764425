<template>
	<div class="pdfjs-viewer--component-wrapper full full_h">
		<b-overlay v-if="iframeLoaded" class="holder full full_h clearfix" opacity="0.85" :id="`iframe-file__${xid}`" :show="isIframeLoading">
			<iframe
        ref="pdfjsVeiwerIframe"
        frameborder="0" 
        class="full full_h no-border"
        :style="isIframeLoading ? 'opacity:0.6;' : ''"
        :src="getFilePath"
        @load="loadingComplete"
        @error="handleIframeError"
      />
			<template #overlay>
				<div class="text-center bg-transparent">
					<p><loading :active.sync="isIframeLoading" loader="bars" color="#00A09C" :width="64" /></p>
					<p class="loading-text">
            {{ FormMSG(100, 'Please wait...') }}<br>
            <span v-show="isLoadingMessageVisible">
              {{ FormMSG(3456, 'Nothing to worry your PDF is on the way!') }}…
            </span>
          </p>
				</div>
			</template>
		</b-overlay>

		<b-overlay v-else class="holder full full_h clearfix" opacity="0.85" :id="`file__${xid}`" :show="isLoading">
			<canvas v-for="page in numPages" :id="`pdfjs-viewer-canvas-${page}`" :ref="`canvas-pdfpage--${page}`" :key="page" />
			<template #overlay>
				<div class="text-center bg-transparent">
					<p><loading :active.sync="isLoading" loader="bars" color="#00A09C" :width="64" /></p>
					<p class="loading-text">
						{{ FormMSG(100, 'Please wait...') }}
					</p>
				</div>
			</template>
		</b-overlay>


		<b-modal
      id="pdfviewer-error-handler"
			header-class="header-class-modal-doc-package"
			:title="FormMSG(7888, 'Error')"
			class="modal-success"
			v-model="isErrorMessageModalOpen"
			@ok="isErrorMessageModalOpen = false"
			header-bg-variant="danger"
			header-border-variant="danger"
			ok-variant="light"
			ok-only
		>
			{{ errorMessage }}
		</b-modal>
	</div>
</template>

<script>
import { getDocument as getPdfDocument, GlobalWorkerOptions } from 'pdfjs-dist-es5/build/pdf';
import { isNil, constructQueries, removeQueryString } from '@/shared/utils';
import mapProps from '@/shared/vuePropsMapper';
import filePreviewerMixin from '@/components/Packages/FIlesPreviewer/filePreviewer.mixin';
import languageMessages from '@/mixins/languageMessages';

import Loading from 'vue-loading-overlay';
import * as _ from 'lodash';

export default {
	name: 'PdfJSReaderFilepreviewerComponent',
	components: { Loading },
	mixins: [filePreviewerMixin, languageMessages],
	props: {
		...mapProps(['src', 'xid'], {
			type: String,
			required: true,
			default: null
		}),
		isPwaIos: {
			type: Boolean,
			required: false,
			default: false
		},
		manager: {
			type: Object,
			required: true,
			default: () => {}
		}
	},
	data() {
		return {
			numPages: 0,
			isLoading: false,
			iframeLoaded: true,
			isIframeLoading: true,
      isErrorMessageModalOpen: false,
      errorMessage: 'Error',
      isLoadingMessageVisible: false,
      loadingTimeout: null,
		};
	},
	mounted() {
    this.iniIframePdf();
		this.initCanvasPdf();
	},
	computed: {
		/**
		 * @return {URI|String}
		 */
		getFilePath() {
			/**
       * @type {String} _q
       * @type {URL|String} url
       */
      const _q = constructQueries({
        file: removeQueryString(this.src),
        v: new Date().getTime(),
      });
      const url = `${process.env.BASE_URL}/pdfjs/web/viewer.html${_q}#page=1`;

      return encodeURI(url);
		}
	},
	methods: {
    // -- iframe PDF builder --
    iniIframePdf() {
      this.showLoadingMessage();
      window.addEventListener('pdfjsError', (event) => {
        this.handlePdfError(event.detail.error);
      });
    },

		handleIframeError() {
			this.iframeLoaded = false;
		},

    loadingComplete() {
      /** @type {HTMLElement} iframe */
      const iframe = this.$refs.pdfjsVeiwerIframe;

      if (isNil(iframe)) {
        this.handlePdfError(this.FormMSG(7997, 'Something went wrong during the loading of the PDF viewer.'));
        return;
      }

      if (isNil(iframe.contentDocument) || isNil(iframe.contentWindow)) {
        this.handlePdfError(this.FormMSG(70097, 'Something went wrong during the processing of your PDF file.'));
        return;
      }

      if (iframe && iframe.contentDocument && iframe.contentDocument.querySelector('.loadingError')) {
        this.isIframeLoading = false;
        this.handlePdfError(this.FormMSG(98797, 'Failed to load PDF'));
      }

      const pdfViewerApplication = iframe.contentWindow.PDFViewerApplication;
      pdfViewerApplication.open({ url: this.src })
        .then(() => {
          this.hideLoadingMessage()
          this.isIframeLoading = false
        })
        .catch((e) => this.handlePdfError(e));
    },

    /**
     * @param {String} errorMsg
     */
    handlePdfError(errorMsg = 'Error') {
      this.errorMessage = errorMsg;
      this.isErrorMessageModalOpen = true;
    },

    // -- base64 PDF builder --
		initCanvasPdf() {
			if (this.iframeLoaded) return;
			GlobalWorkerOptions.workerSrc = `${process.env.BASE_URL}/js/pdf.worker.js`;
			this.setupPdfJS();
		},

		async setupPdfJS() {
			if (isNil(this.src)) return;
			this.isLoading = true; // Start loading
			try {
				const pdf = await getPdfDocument(this.src).promise;
				this.numPages = pdf.numPages;

				// Loop through each page and set it up
				for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
					const page = await pdf.getPage(pageNum);

					const viewport = page.getViewport({ scale: 3 });

					// Prepare canvas using PDF page dimensions
					const canvas = document.getElementById(`pdfjs-viewer-canvas-${pageNum}`);
					const context = canvas.getContext('2d');
					canvas.width = viewport.width;
					canvas.height = viewport.height;

					// Render PDF page into canvas context
					const renderContext = {
						canvasContext: context,
						viewport: viewport
					};
					const renderTask = page.render(renderContext);
					renderTask.promise.then(function () {
						console.log(`Page ${pageNum} rendered`);
					});
				}
			} catch (error) {
				console.error('Error loading PDF:', error);
			} finally {
				this.isLoading = false;
			}
		},

    showLoadingMessage() {
      this.loadingTimeout = setTimeout(() => this.isLoadingMessageVisible = true, 2000);
    },
    hideLoadingMessage() {
      clearTimeout(this.loadingTimeout);
      setTimeout(() => this.isLoadingMessageVisible = false, 100);
    },
	},
  beforeDestroy() {
    window.removeEventListener('pdfjsError', (e) => this.handlePdfError(e.detail.error));
  }
};
</script>

<style lang="scss">
.pdfjs-viewer--component-wrapper {
	overflow-y: scroll;
	.holder {
		display: flex;
		flex-direction: column;

		& > canvas:not(:last-child) {
			margin-bottom: 10px;
		}
	}
}
</style>
