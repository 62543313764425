var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "main-container--image-show-room",
      class: [
        { "is-panel-open": _vm.isCommentPanelOpen },
        _vm.rendPresentationModeOptionClass,
      ],
    },
    [
      _c(
        "div",
        {
          staticClass: "carousel-container",
          class: _vm.rendCarouselContainerClass,
          on: {
            mouseenter: _vm.handleCarouselMouseEnter,
            mouseleave: _vm.handleCarouselMouseLeave,
          },
        },
        [
          _vm._l(_vm.imagesList, function ({ xid, src, ext }, i) {
            return _c(
              "div",
              {
                key: i,
                staticClass: "carousel-child",
                class: [
                  { "is-active": _vm.currentActiveSlide === xid },
                  `file-type--${_vm.presentationMode}`,
                ],
              },
              [
                _vm.isElementPositioningLoad
                  ? _c(
                      "div",
                      {
                        staticClass: "ghost-loading-pinup",
                        style: _vm.ghostLoadingPinUpPosition,
                      },
                      [_c("b-spinner", { attrs: { small: "" } })],
                      1
                    )
                  : _vm._e(),
                _vm.canDropAnotation
                  ? _c("PositionedPins", {
                      attrs: {
                        manager: _vm.manager,
                        xid: xid,
                        "dropzone-ref": `droppingZoneRef_${i}`,
                        "image-ref": `slideImg_${i}`,
                      },
                    })
                  : _vm._e(),
                _vm.canDropAnotation
                  ? _c("div", {
                      key: `droppingZoneKey_${xid}_${i}`,
                      ref: `droppingZoneRef_${i}`,
                      refInFor: true,
                      staticClass: "carousel-child--drop-zone-layer",
                      class: {
                        "is-drop-zone-active": _vm.isAnnotationToolActive,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleDroppedElement($event, i)
                        },
                      },
                    })
                  : _vm._e(),
                _c(
                  "div",
                  {
                    key: `carouselChildKey_${xid}_${i}`,
                    staticClass: "carousel-child--content",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "carousel-child--attachment-container" },
                      [
                        _vm.currentActiveSlide === xid
                          ? _c(
                              "div",
                              {
                                key: `carouselChildAttachementKey_${xid}_${i}`,
                                staticClass: "carousel-child--attachment-file",
                                class: `file-type--${_vm.presentationMode}`,
                                staticStyle: { padding: "0px" },
                              },
                              [
                                ext === "pdf"
                                  ? _c("PdfViewer", {
                                      ref: `slidePdf_${i}`,
                                      refInFor: true,
                                      attrs: {
                                        "is-pwa-ios": _vm.isPwaIOS,
                                        src: src,
                                        xid: xid,
                                        manager: _vm.manager,
                                      },
                                    })
                                  : ext.match(/(jpg|jpeg|png|gif)/g)
                                  ? _c("img", {
                                      ref: `slideImg_${i}`,
                                      refInFor: true,
                                      attrs: { ext: ext, src: src },
                                    })
                                  : ext.match(/(docx|pptx|xlsx)/g) &&
                                    !_vm.isBackendLocalhost
                                  ? _c("WordMDDocViewer", {
                                      ref: `slideImg_${i}`,
                                      refInFor: true,
                                      attrs: { src: src, ext: ext, xid: xid },
                                    })
                                  : ext === "md" || ext === "txt"
                                  ? _c("MarkdownDocViewer", {
                                      ref: `slideImg_${i}`,
                                      refInFor: true,
                                      attrs: { src: src, xid: xid },
                                    })
                                  : _c("OtherFileViewer", {
                                      ref: `slideOther_${i}`,
                                      refInFor: true,
                                      attrs: { src: src, manager: _vm.manager },
                                    }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    ),
                  ]
                ),
              ],
              1
            )
          }),
          !_vm.isCommentPanelOpen && _vm.isMultiple
            ? _c(
                "div",
                {
                  staticClass: "carousel-control-left",
                  on: {
                    click: function ($event) {
                      return _vm.handleChangeSlide(-1)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "carousel-control-btn" },
                    [_c("ArrowIconSVG")],
                    1
                  ),
                ]
              )
            : _vm._e(),
          !_vm.isCommentPanelOpen && _vm.isMultiple
            ? _c(
                "div",
                {
                  staticClass: "carousel-control-right",
                  on: {
                    click: function ($event) {
                      return _vm.handleChangeSlide(1)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "carousel-control-btn" },
                    [_c("ArrowIconSVG")],
                    1
                  ),
                ]
              )
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }