<template>
	<div
		ref="commentsListArea"
		style="width: 100%"
		class="comment-list-items-wrapper d-inline-block"
		:show="manager.states.isCommentListLoading"
		rounded
		opacity="0.6"
		spinner-small
		spinner-variant="primary"
	>
		<ul v-if="$has(commentsList) && commentsList.length" style="padding-left: 0">
			<CommentListItem v-for="(comment, i) in commentsList" v-show="comment.message.length" :key="i" :manager="manager" :comment="comment" />
		</ul>
		<span v-else>{{ FormMSG(4, 'No comment') }}</span>
	</div>
</template>

<script>
import CommentListItem from '@/components/Packages/FIlesPreviewer/components/Comments/CommentItem';
import languageMessages from '@/mixins/languageMessages';

export default {
	name: 'CommentsCommentsListFIlesPreviewerComponent',
	components: { CommentListItem },
	mixins: [languageMessages],
	props: {
		manager: {
			type: Object,
			required: true,
			default: () => {}
		}
	},
	computed: {
		/**
		 * @return {Array}
		 */
		commentsList() {
			return this.manager.states.commentsList;
		},

		/**
		 * @return {Array}
		 */
		isCommentPanelOpen() {
			return this.manager.states.isCommentPanelOpen;
		}
	},
	watch: {
		isCommentPanelOpen: {
			/**
			 * @param {Boolean} status
			 */
			handler(status) {
				if (!status) return;
				this.setScroll();
			},
			immediate: true
		}
	},
	mounted() {
		this.setScroll();
	},
	methods: {
		setScroll() {
			this.autoScrollToEnd('commentsListArea');
		}
	}
};
</script>

<style lang="scss" scope>
.comment-list-items-wrapper {
	width: 100%;
	height: 100%;
	overflow-x: scroll;
}
</style>
