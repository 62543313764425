var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "submit-file-change-btn--component-wrapper" },
    [
      _c(
        "b-button",
        {
          attrs: {
            id: "tooltip-target-87557897",
            variant: "primary",
            disabled: _vm.isDisbaled,
          },
          on: { click: _vm.handleSubmitPdfChanges },
        },
        [
          _vm.manager.states.isUploadFileLoading
            ? _c("b-spinner", {
                staticStyle: { "margin-right": "15px" },
                attrs: { small: "" },
              })
            : _vm._e(),
          _vm._v(
            "\n\t\t" +
              _vm._s(_vm.FormMSG(8997897, "Submit PDF's changes")) +
              "\n\t"
          ),
        ],
        1
      ),
      _c(
        "b-tooltip",
        { attrs: { target: "tooltip-target-87557897", triggers: "hover" } },
        [
          _vm._v(
            "\n\t\t" +
              _vm._s(
                _vm.FormMSG(
                  908908,
                  "All your changes in the pdf gonna be saved and send to the administration"
                )
              ) +
              "\n\t"
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }