var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pdfjs-viewer--component-wrapper full full_h" },
    [
      _vm.iframeLoaded
        ? _c(
            "b-overlay",
            {
              staticClass: "holder full full_h clearfix",
              attrs: {
                opacity: "0.85",
                id: `iframe-file__${_vm.xid}`,
                show: _vm.isIframeLoading,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "overlay",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          { staticClass: "text-center bg-transparent" },
                          [
                            _c(
                              "p",
                              [
                                _c("loading", {
                                  attrs: {
                                    active: _vm.isIframeLoading,
                                    loader: "bars",
                                    color: "#00A09C",
                                    width: 64,
                                  },
                                  on: {
                                    "update:active": function ($event) {
                                      _vm.isIframeLoading = $event
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("p", { staticClass: "loading-text" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.FormMSG(100, "Please wait..."))
                              ),
                              _c("br"),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.isLoadingMessageVisible,
                                      expression: "isLoadingMessageVisible",
                                    },
                                  ],
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.FormMSG(
                                          3456,
                                          "Nothing to worry your PDF is on the way!"
                                        )
                                      ) +
                                      "…\n            "
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                1606431946
              ),
            },
            [
              _c("iframe", {
                ref: "pdfjsVeiwerIframe",
                staticClass: "full full_h no-border",
                style: _vm.isIframeLoading ? "opacity:0.6;" : "",
                attrs: { frameborder: "0", src: _vm.getFilePath },
                on: { load: _vm.loadingComplete, error: _vm.handleIframeError },
              }),
            ]
          )
        : _c(
            "b-overlay",
            {
              staticClass: "holder full full_h clearfix",
              attrs: {
                opacity: "0.85",
                id: `file__${_vm.xid}`,
                show: _vm.isLoading,
              },
              scopedSlots: _vm._u([
                {
                  key: "overlay",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "text-center bg-transparent" }, [
                        _c(
                          "p",
                          [
                            _c("loading", {
                              attrs: {
                                active: _vm.isLoading,
                                loader: "bars",
                                color: "#00A09C",
                                width: 64,
                              },
                              on: {
                                "update:active": function ($event) {
                                  _vm.isLoading = $event
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c("p", { staticClass: "loading-text" }, [
                          _vm._v(
                            "\n\t\t\t\t\t\t" +
                              _vm._s(_vm.FormMSG(100, "Please wait...")) +
                              "\n\t\t\t\t\t"
                          ),
                        ]),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            _vm._l(_vm.numPages, function (page) {
              return _c("canvas", {
                key: page,
                ref: `canvas-pdfpage--${page}`,
                refInFor: true,
                attrs: { id: `pdfjs-viewer-canvas-${page}` },
              })
            }),
            0
          ),
      _c(
        "b-modal",
        {
          staticClass: "modal-success",
          attrs: {
            id: "pdfviewer-error-handler",
            "header-class": "header-class-modal-doc-package",
            title: _vm.FormMSG(7888, "Error"),
            "header-bg-variant": "danger",
            "header-border-variant": "danger",
            "ok-variant": "light",
            "ok-only": "",
          },
          on: {
            ok: function ($event) {
              _vm.isErrorMessageModalOpen = false
            },
          },
          model: {
            value: _vm.isErrorMessageModalOpen,
            callback: function ($$v) {
              _vm.isErrorMessageModalOpen = $$v
            },
            expression: "isErrorMessageModalOpen",
          },
        },
        [_vm._v("\n\t\t\t" + _vm._s(_vm.errorMessage) + "\n\t\t")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }