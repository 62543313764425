<template>
	<div ref="default-container" class="app" :class="{ 'no-scroll': isShowRoomPictureOpen, 'is-inpwa': $isPwa() }">
		<AppHeader fixed :class="{ 'is-inpwa': $isPwa() }" :key="`${keyVersion}1`">
			<b-row class="ml-0">
				<BackInHistoryBtn v-if="$isPwa()" :key="`${keyVersion}2`" class="mr-3" />
				<SidebarToggler class="d-lg-none" display="sm" mobile :key="`${keyVersion}3`" />
				<a class="navbar-brand" style="cursor: pointer" :href="`#/my-dashboard?v=${version}`">
					<!--<a class="navbar-brand" style="cursor: pointer" @click="backToMyDashboard">-->
					<img class="navbar-brand-full" src="img/brand/thegreenshot-brand.png" width="100" height="30" alt="TheGreenShot" />
					<img class="navbar-brand-minimized" src="img/brand/tgs-icon.svg" width="30" height="30" alt="TGS" />
				</a>
				<SidebarToggler class="d-md-down-none" display="sm" :key="`${keyVersion}4`" />
				<b-navbar-nav class="d-md-down-none">
					<HeaderProjectToggler :key="`${keyVersion}5`" />
				</b-navbar-nav>
			</b-row>
			<b-row class="mr-0">
				<b-navbar-nav class="d-flex" style="gap: 5px">
					<button type="button" class="btn btn-outline-primary btn-sm d-flex" style="gap: 5px" @click="backToMyDashboard">
						<home :size="16" /><span v-if="$screen.width >= 992">{{ FormMSG(44, 'Home') }}</span>
					</button>
					<HeaderLanguageToggler :key="`${keyVersion}6`" />
					<div>
						<b-dropdown
							size="sm"
							id="dropdown-header-help"
							:text="menuLabel()"
							class="dropdown--large"
							v-if="$screen.width >= 992"
							variant="outline-primary"
							right
						>
							<b-dropdown-item @click="openDocumentation()">
								{{ FormMSG(19946, 'Online guide') }}
							</b-dropdown-item>
							<b-dropdown-item @click="downloadStartUpGuide()">
								{{ FormMSG(19947, 'Quick Startup PDF Guide') }}
							</b-dropdown-item>
							<b-dropdown-item v-if="isFilmSingle" @click="showWizard">
								{{ FormMSG(19948, 'View wizard') }}
							</b-dropdown-item>
						</b-dropdown>
						<b-dropdown
							size="sm"
							id="dropdown-header-help"
							variant="outline-primary"
							toggle-class="text-decoration-none"
							class="dropdown--large"
							no-caret
							v-else
						>
							<template #button-content>
								<help-circle :size="16" />
							</template>
							<b-dropdown-item @click="openDocumentation()">
								{{ FormMSG(19946, 'Online guide') }}
							</b-dropdown-item>
							<b-dropdown-item @click="downloadStartUpGuide()">
								{{ FormMSG(19947, 'Quick Startup PDF Guide') }}
							</b-dropdown-item>
							<b-dropdown-item v-if="isFilmSingle" @click="showWizard">
								{{ FormMSG(19948, 'View wizard') }}
							</b-dropdown-item>
						</b-dropdown>
					</div>
					<div>
						<HeaderNotifications :key="`${keyVersion}7`" :app-version="version" />
					</div>
					<b-nav-item>
						<DefaultHeaderDropdownAccnt ref="dropdown-accnt" :key="`${keyVersion}8`" :app-version="version" />
					</b-nav-item>
				</b-navbar-nav>
			</b-row>
			<!-- <AsideToggler class="d-none d-lg-block" /> -->
			<!--<AsideToggler class="d-lg-none" mobile />-->
		</AppHeader>
		<div
			class="app-body"
			:class="{
				'is-pwa': $isPwa(),
				'is-ios': $isIOSDevice(),
				'is-android': $isAndroidDevice()
			}"
		>
			<AppSidebar dropdown-mode="close" fixed :key="`${keyVersion}9`">
				<SidebarHeader :key="`${keyVersion}10`" />
				<SidebarForm :key="`${keyVersion}11`" />
				<SidebarNav :nav-items="getNavItems" class="bg-secondary" :key="`${keyVersion}12`" />
				<SidebarFooter :key="`${keyVersion}13`" />
				<SidebarMinimizer :key="`${keyVersion}14`" />
			</AppSidebar>
			<main class="main">
				<Breadcrumb v-if="$screen.width < 576" :list="list" />
				<div class="container-fluid pt-3" :key="`${keyVersion}18`">
					<router-view />
				</div>
			</main>
			<AppAside fixed :key="`${keyVersion}15`">
				<!--aside-->
				<DefaultAside :key="`${keyVersion}16`" />
			</AppAside>
		</div>
		<TheFooter :key="`${keyVersion}17`">
			<!--footer-->
			<div class="ml-auto">
				<span class="ml-1">&copy; {{ copyright }} </span>
				<a href="https://www.thegreenshot.green" target="_blank" rel="noopener noreferrer">TheGreenShot</a>
			</div>
		</TheFooter>
		<tip-modal-component :key="`${keyVersion}18`" />
		<help-modal-component :key="`${keyVersion}19`" />
		<PreviewShowRoomImage :key="`${keyVersion}20`" />
		<modal-wizard :key="`${keyVersion}21`" />
	</div>
</template>

<script>
import nav from '@/_nav';
import {
	Header as AppHeader,
	SidebarToggler,
	Sidebar as AppSidebar,
	SidebarFooter,
	SidebarForm,
	SidebarHeader,
	SidebarMinimizer,
	Aside as AppAside,
	// AsideToggler,
	// SidebarNav,
	Footer as TheFooter,
	Breadcrumb
} from '@coreui/vue';
import SidebarNav from '@/components/Forks/SidebarNav';
import DefaultAside from './DefaultAside';
import DefaultHeaderDropdownAccnt from './DefaultHeaderDropdownAccnt';
import HeaderProjectToggler from '../components/HeaderProjectToggler';
import HeaderLanguageToggler from '../components/HeaderLanguageToggler';
import HeaderNotifications from '../components/HeaderNotifications';
import TipModalComponent from '../components/Modals/TipModal';
import ModalWizard from '../components/Modals/ModalWizard';
import HelpModalComponent from '../components/Modals/HelpModal';
import BackInHistoryBtn from '@/components/BackInHistoryBtn';
import PreviewShowRoomImage from '@/components/Packages/FIlesPreviewer/components/MainModalContainer';
import languageMessages from '@/mixins/languageMessages';
import { GetRandomTipOfDay } from '@/cruds/tipofday.crud';
import { isNil } from '@/shared/utils';
import { getParamVersion } from '@/shared/version';
import moment from 'moment-timezone';
import { Wand, HelpCircle, Home } from 'lucide-vue';
import { store } from '@/store';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import _ from 'lodash';
import { isEmpty } from '../shared/utils';
import { mapGetters, mapActions } from 'vuex';

function isFeatureAllowed(feature, projectConfig) {
	const isFeatureRestricted =
		(feature.name === 'Budget' && !projectConfig.useBudget) ||
		(feature.name === 'My budget' && !projectConfig.useBudget) ||
		(feature.name === 'Tasks' && !projectConfig.useTask) ||
		(feature.name === 'Task types' && !projectConfig.useTask) ||
		(feature.name === 'My Tasks' && !projectConfig.useTask) ||
		(feature.name === 'My Planning' && !projectConfig.useTask) ||
		(feature.name === 'Planning' && !projectConfig.useTask) ||
		(feature.name === 'Suppliers' && !projectConfig.useGreenSupplier) ||
		(feature.name === 'Carbon template' && !projectConfig.useCarbonTemplate) ||
		(feature.name === 'Reporting' && !projectConfig.useReporting) ||
		(feature.name === 'Carbon removal' && !projectConfig.useCarbonRemoval) ||
		(feature.name === 'Waste' && !projectConfig.useWaste) ||
		(feature.name === 'Green suppliers' && !projectConfig.useGreenSupplier) ||
		(feature.name === 'Contacts' && !projectConfig.useContacts) ||
		(feature.name === 'Timesheets planning' && !projectConfig.useTimesheetPlanning) ||
		(feature.name === 'Tip of the day' && !projectConfig.useTipOfDay) ||
		(feature.name === 'Validations' && !(projectConfig.useExpense || projectConfig.usePurchaseOrder || projectConfig.useTimesheet)) ||
		(feature.name === 'My tip of the day' && !projectConfig.useTipOfDay) ||
		(feature.name === 'Crew Setup' &&
			((!store.canManageCrewList() &&
				!store.canManageDocumentPackage() &&
				!(
					store.state.myProfile.upm ||
					store.state.myProfile.gateKeeper ||
					store.state.myProfile.productionAccountant ||
					store.state.myProfile.lineProducer ||
					store.state.myProfile.studio ||
					store.state.myProfile.canValidateI9
				)) ||
				!projectConfig.useCrewSetup)) ||
		(feature.name === 'Documents' &&
			(!store.canManageDocuments() || !store.canManageCallSheets() || (!projectConfig.useDocument && !projectConfig.useCallSheet))) ||
		(feature.url === '/locations' && (!store.canManageLocations() || !projectConfig.useLocations)) ||
		(feature.name === 'Waste' && !store.canViewWasteScreen()) ||
		(feature.hasOwnProperty('notForMobile') && window.innerWidth <= 576) ||
		(feature.hasOwnProperty('useGreenTable') && (!store.canViewGreenTable() || !projectConfig.useGreenTable)) ||
		(feature.name === 'Carbon template' && !store.canViewGreenTable()) ||
		(feature.name === 'Imports' && (!store.canExportImport() || !projectConfig.useImport)) ||
		(feature.name === 'Exports' && (!store.canExportImport() || !projectConfig.useExport)) ||
		(feature.name === 'Reporting' && !store.canAccessGreenReporting()) ||
		(feature.name === 'Carbon removal' && !store.canManageCarbonRemoval()) ||
		(feature.name === 'Configuration' && !store.canAccessConfig()) ||
		(feature.name === 'Budget GL' && (!store.canViewGlobalBudget() || !projectConfig.useBudget)) ||
		(feature.name === 'Transports' && !projectConfig.useTransport) ||
		(feature.name === 'My Transports' && !projectConfig.useTransport) ||
		(feature.name === 'Accomodations' && !projectConfig.useAccommodation) ||
		(feature.name === 'My Accomodations' && !projectConfig.useAccommodation) ||
		(feature.name === 'Special equipments' && (!store.canViewGreenTable() || !projectConfig.useGreenTable)) ||
		(feature.name === 'My Work' && !projectConfig.useTimesheet) ||
		(feature.name === 'My Timesheets' && !projectConfig.useTimesheet) ||
		(feature.name === 'Validate Work' && !projectConfig.useTimesheet) ||
		(feature.name === 'My Expenses' && !projectConfig.useExpense) ||
		(feature.name === 'Validate Expenses' && !projectConfig.useExpense) ||
		(feature.name === 'My PO' && !projectConfig.usePurchaseOrder) ||
		(feature.name === 'Validate P-Os' && !projectConfig.usePurchaseOrder) ||
		(feature.hasOwnProperty('notForFilm') && store.isFilm()) ||
		(feature.hasOwnProperty('haveActiveReporting') && (!store.greenReporting() === 1 || !store.greenReporting() === 2 || !store.greenReporting() === 3)) ||
		(feature.hasOwnProperty('filmOnly') && !store.isFilm() && !store.isFilmSingleUser() && !store.isFilmSingleUserFree()) ||
		(feature.hasOwnProperty('pmeOnly') && !store.isPme()) ||
		(feature.hasOwnProperty('prodOnly') && !projectConfig.useProduction) ||
		(feature.hasOwnProperty('useFinance') && !projectConfig.useFinance) ||
		(feature.hasOwnProperty('useGreen') && !projectConfig.useGreen) ||
		(feature.hasOwnProperty('greenReporting') && store.greenReporting() != feature.greenReporting) ||
		(feature.hasOwnProperty('translatorOnly') && !store.isTranslator()) ||
		(feature.hasOwnProperty('notForSingleUser') && (store.isFilmSingleUser() || store.isFilmSingleUserFree())) ||
		(feature.hasOwnProperty('devOnly') && !store.isDev()) ||
		(feature.hasOwnProperty('canAccessMyBudgetOnly') && !store.canAccessMyBudget()) ||
		(feature.hasOwnProperty('accessForCrew') &&
			!(store.canAccessTimesheetEntryForCrew() || store.canAccessExpenseEntryForCrew() || store.canAccessPoEntryForCrew())) ||
		(feature.hasOwnProperty('forCrewTimesheet') && !store.canAccessTimesheetEntryForCrew()) ||
		(feature.hasOwnProperty('forCrewExpense') && !store.canAccessExpenseEntryForCrew()) ||
		(feature.hasOwnProperty('forCrewPO') && !store.canAccessPoEntryForCrew()) ||
		(feature.hasOwnProperty('isCarbonClap') && !store.isCarbonClapReporting()) ||
		(feature.hasOwnProperty('canAccessGreenReporting') && !store.canAccessGreenReporting()) ||
		(feature.hasOwnProperty('notForSingleProject') && (store.isFilmSingleUserFree() || store.isFilmSingleUser()));
	// console.log("store:",store);
	return !isFeatureRestricted;
}

function removeNotAllowedChildren(menu, projectConfig) {
	// menu.savedChildren = [...menu.children];
	if (menu.savedChildren.length === 0) {
		for (let i = 0; i < menu.children.length; i++) {
			menu.savedChildren.push(menu.children[i]);
		}
		//console.log("in ISNILI removeNotAllowedChildren SAVED CHILDREN AFTER:",menu.savedChildren);
	}
	// filling newChildren with authorized children
	// menu.children.splice(0, menu.children.length); // empty children array
	menu.children = []; // empty children array

	for (let i = 0; i < menu.savedChildren.length; i++) {
		const saved = menu.savedChildren[i];
		// console.log(menu.savedChildren[i],isFeatureAllowed(menu.savedChildren[i]) )
		if (isFeatureAllowed(saved, projectConfig)) {
			menu.children.push(saved);
		}
	}

	// console.log("in removeNotAllowedChildren NEWCHILDREN:",menu);
	return menu;
}
// console.log("in default container")
function removeItemsWithNoChildren(arr) {
	const filteredArray = arr.filter((item) => item.children.length > 0);
	return filteredArray;
}

export default {
	name: 'DefaultContainer',
	components: {
		// AsideToggler,
		AppHeader,
		AppSidebar,
		AppAside,
		TheFooter,
		Breadcrumb,
		DefaultAside,
		DefaultHeaderDropdownAccnt,
		SidebarForm,
		BackInHistoryBtn,
		SidebarFooter,
		SidebarToggler,
		SidebarHeader,
		SidebarNav,
		SidebarMinimizer,
		HeaderProjectToggler,
		HeaderLanguageToggler,
		HeaderNotifications,
		TipModalComponent,
		HelpModalComponent,
		PreviewShowRoomImage,
		Wand,
		HelpCircle,
		Home,
		ModalWizard
	},
	mixins: [languageMessages, isSingleProjectMixin],
	data: function () {
		return {
			// Initialize your apollo data
			shared: store.state,
			tipOfTheDay: {},
			version: getParamVersion(),
			project: null,
			screenWidth: window.innerWidth,
			isTokenChanged: false,
			// isLanguageChanged: false,
			isMobile: false
		};
	},
	created() {
		moment.tz.setDefault('GMT'); // force using absolute date in GMT
		// Add an event listener to update the screen width when the window is resized
		window.addEventListener('resize', this.updateScreenWidth);
		// call to generate the menu.
		// console.log('CREATED HOOK...');
		this.generateNavigation();
	},
	async beforeDestroy() {
		// Remove the event listener to prevent memory leaks
		window.removeEventListener('resize', this.updateScreenWidth);
	},
	watch: {
		$route: {
			async handler(to, from) {},
			immediate: true
		},
		isMobile: function (newValue, oldValue) {
			// Perform action when 'screen-size' is like a mobilescreen
			this.isTokenChanged = true;
			// this.navItems = [];
			this.generateNavigation();
		},
		'shared.token': function (newValue, oldValue) {
			// Perform action when 'token' in the store changes
			// console.log('Token change...');
			if (!isNil(newValue)) {
				this.isTokenChanged = true;
				// this.navItems = [];
				this.generateNavigation();
			}
		},
		'shared.myProject.id': function (newValue, oldValue) {
			// Perform action when 'projectId' in the store changes
			// console.log('Project change...');
			if (!isNil(newValue) && newValue !== oldValue) {
				this.isTokenChanged = true;
				// this.navItems = [];
				this.generateNavigation();
			}
		}
	},
	computed: {
		// async GetTipOfTheDay() {
		//   //  console.log("results for tip of day0:",  this.tipOfTheDay);
		//   this.tipOfTheDay = await GetRandomTipOfDay(1020)
		//   this.$bvModal.show('tip-of-the-day-modal')
		//   //  console.log("results for tip of day:",  this.tipOfTheDay);
		// },
		...mapGetters({
			getNavItems: 'nav-items/getNavItems'
		}),
		name() {
			return this.$route.name;
		},
		list() {
			return this.$route.matched.filter((route) => route.name || route.meta.label);
		},
		/**
		 * @return {Boolean}
		 */
		isShowRoomPictureOpen() {
			return this.$store.getters['app/isShowRoomPictureOpen'];
		},
		// async navItems() {
		// },
		copyright() {
			return moment().format('YYYY');
		},
		keyVersion() {
			const version = getParamVersion();

			return version.replace('?v=', '');
		}
	},
	methods: {
		...mapActions({
			mutateNavItems: 'nav-items/mutateNavItems'
		}),
		async generateNavigation() {
			//get current my project
			const currentProjectConfig = store.getCurrentProjectConfig();
			/**
			 * check if there is not already a latest Menu items or saved
			 * if there is a change inside the token
			 * and removing not allowed children from menus
			 */
			if (!this.getNavItems[0] || this.isTokenChanged) {
				// filter menu
				let newNavs = [];
				for (const elem of nav) {
					const result = removeNotAllowedChildren(elem, currentProjectConfig);
					newNavs.push(result);
				}

				// remove not allowed children.
				const selectedFilterMenus = newNavs.filter((item) => isFeatureAllowed(item, currentProjectConfig));
				const selectedMenuOptions = removeItemsWithNoChildren(selectedFilterMenus);
				//get noTranslationMenus stored.
				const noTranslationMenus = store.getSavedNoTranslationMenus();
				// translate menu if needed
				// let selectedMenuOptionsTranslated = [];
				const selectedMenuOptionsTranslated = this.translateMenuName(selectedMenuOptions, noTranslationMenus);
				// await Promise.all(menuOptionsTranslatedPromise).then((resolvedArray) => {
				// 	selectedMenuOptionsTranslated = resolvedArray;
				// });
				//console.log("test selectedMenuOptionsTranslated:",selectedMenuOptionsTranslated);

				const mergedMenus = this.mergeArrays(this.getNavItems, selectedMenuOptionsTranslated, 'msgId');
				await this.mutateNavItems({ isUpdate: false, navs: mergedMenus });
				// reset token change state
				this.isTokenChanged = false;
			}
		},
		translateMenuName(items, noTranslationMenus) {
			return items.map((item) => {
				const id = this.getMsgIdForProjectType(item);
				let message = '';
				if (item.hasOwnProperty('titleUpdatable') && item.titleUpdatable === true) {
					message = this.getCostumTitle(id, {
						userName: noTranslationMenus.userName,
						hodName: noTranslationMenus.hodName,
						managerName: noTranslationMenus.managerName
					});

					if (message === null) {
						message = this.FormMSG(id, this.getMsgNameForProjectType(item));
					}
				} else {
					message = this.FormMSG(id, this.getMsgNameForProjectType(item));
				}

				// format the final menu
				let menu = {
					...item,
					name: message
				};

				if (item.hasOwnProperty('children') && item.children.length > 0) {
					menu.children = this.translateMenuName(menu.children, noTranslationMenus);

					// await Promise.all(menuChildrenPromise).then((resolvedArray) => {
					// 	menu.children = resolvedArray;
					// });
				}

				return menu;
			});
		},
		mergeArrays(oldArray, newArray, uniqueIdentifier) {
			// console.log(oldArray);
			// console.log(newArray);
			if (!oldArray.length) {
				return newArray;
			} else {
				const mergedArray = newArray.map((item1) => {
					const matchingItem = oldArray.find((item2) => item2[uniqueIdentifier] === item1[uniqueIdentifier] && !item2['name'].includes('_'));

					if (matchingItem) {
						// Merge changes from item2 into item1
						return { ...item1, open: matchingItem.open };
					}

					// If no match found, keep item1 unchanged
					return item1;
				});

				return mergedArray;
			}
		},
		updateScreenWidth() {
			this.screenWidth = window.innerWidth; // Update screen width when called
			this.isMobile = false;
			if (this.screenWidth < 576) {
				this.isMobile = true;
			}
		},
		showWizard() {
			this.$bvModal.show('wizard-modal');
		},
		backToMyDashboard() {
			this.$router.push({ name: 'My Dashboard', query: { v: this.version } }).catch(() => {});
		},
		menuLabel() {
			return this.FormMSG(19998, 'Help');
		},
		getMsgIdForProjectType(feature) {
			//  console.log("in getMsgIdForProjectType feature:",feature);
			if (store.isPme()) {
				if (feature.hasOwnProperty('msgIdPme')) {
					//console.log("in getMsgIdForProjectType FOUND msgIdPme:",feature);
					return feature.msgIdPme;
				}
			}
			return feature.msgId;
		},
		getMsgNameForProjectType(feature) {
			if (store.isPme()) {
				if (feature.hasOwnProperty('namePme')) {
					//console.log("in getMsgIdForProjectType FOUND namePME:",feature);
					return feature.namePme;
				}
			}
			return feature.name;
		},
		getCostumTitle(id, { userName, hodName, managerName }) {
			let title = null;
			if ((id === 71 || id === 171) && userName !== '') {
				title = userName;
			}

			if ((id === 11 || id === 111) && hodName !== '') {
				title = hodName;
			}

			if ((id === 18 || id === 118) && managerName !== '') {
				title = managerName;
			}

			return title;
		},
		downloadStartUpGuide() {
			let currLang = store.appLanguage();
			//     console.log("currLang=",currLang);
			//     console.log("myProject=",store.getCurrentProjectConfig());
			let userLevel = store.isProd() ? 2 : store.isAdmin() ? 1 : 0;
			let projectType = store.getCurrentProjectConfig().projectType;
			var urlStr = process.env.VUE_APP_GQL + '/getQuickStartUpGuide/' + currLang + '/' + projectType + '/' + userLevel;

			if ((store.isFilmSingleUserFree() || store.isFilmSingleUser()) && currLang === 1) {
				urlStr = 'https://docs.thegreenshot.green/fr-quick-solo-film/';
			} else if ((store.isFilmSingleUserFree() || store.isFilmSingleUser()) && currLang !== 1) {
				urlStr = 'https://docs.thegreenshot.green/en-quick-start-solo-film/';
			}
			window.open(urlStr); // in a new window
		},
		openDocumentation() {
			this.$bvModal.show('help-documentation-modal');
		}
	}
};
</script>

<style>
#dropdown-header-help .dropdown-menu.dropdown-menu-right.show {
	min-width: 240px;
}
.app.no-scroll {
	overflow: hidden !important;
	height: 100vh !important;
}
</style>
