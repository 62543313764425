<template>
	<li class="comment-feed-item image_comment_list_item_wrapper_component">
		<div class="block">
			<div class="side_avatar" v-lazy-container="{ selector: 'img' }">
				<img class="img-circle-comment" :data-src="rendSrcImg" :alt="rendAltImg" data-error="img/brand/avatar.jpg" data-loading="img/brand/tenor.gif" />
			</div>

			<div class="side_content">
				<div class="block_content-header">
					<div class="header-content">
						<div class="header-content--left">
							<span class="user-name">
								{{ comment.firstName }}
							</span>
						</div>
						<div class="header-content--right">
							<span class="metadata" style="margin-right: 10px">
								<span class="timestamp">
									<small>{{ dateToLiteral }}</small>
								</span>
							</span>
							<b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" no-caret>
								<template #button-content>
									<i class="icon-arrow-down" />
								</template>
								<!-- <b-dropdown-item @click="handleEditComment">
									<span>
										{{ FormMSG(76544343, 'Edit comment') }}
									</span>
								</b-dropdown-item> -->
								<b-dropdown-item @click="handleDelComment">
									<span class="text-danger">
										{{ FormMSG(6879089, 'Delete comment') }}
									</span>
								</b-dropdown-item>
							</b-dropdown>
						</div>
					</div>
				</div>
				<div class="block_content-body">
					<span v-if="comment.pinInfoCode !== 0" class="pin-indication" @mouseover="handPinLabelOrder(true)" @mouseleave="handPinLabelOrder(false)">
						{{ comment.pinInfoCode }}
					</span>
					<span v-html="rendMessage" />
				</div>
			</div>
		</div>
	</li>
</template>

<script>
import moment from 'moment';
import mapProps from '@/shared/vuePropsMapper';
import { mapFilters } from '~filters';
import { capitalize } from '~utils';
import { isNil, urlMatcher } from '@/shared/utils';
import languageMessages from '@/mixins/languageMessages';

export default {
	name: 'ItemesCommentListItemComponent',
	mixins: [languageMessages],
	props: mapProps(['comment', 'manager'], {
		type: Object,
		required: true,
		default: () => {}
	}),
	data() {
		return {
			imgOptions: {
				blankColor: '#777',
				width: 32,
				height: 32,
				class: 'm1'
			}
		};
	},
	computed: {
		/**
		 * @return {String}
		 */
		rendDate() {
			const d = this.comment.insertedOn;
			return `<small>${this.forHuman(d)}</small>`;
		},

		dateToLiteral() {
			const langCurrent = this.currentLang;
			const fullLiteralDate = capitalize(
				moment(this.comment.insertedOn)
					.locale(`${langCurrent}`)
					.format(`dddd Do MMM YYYY [${this.FormMSG(2, 'at')}] HH:mm`)
			);

			return fullLiteralDate;
		},

		/**
		 * @return {String}
		 */
		rendSrcImg() {
			const path = process.env.VUE_APP_PATH_IMG_URL;
			return path + this.comment.picture;
		},

		/**
		 * @return {Html}
		 */
		rendMessage() {
			const _m = this.comment.message;
			if (isNil(_m) || _m === '') return '';
			return urlMatcher(_m);
		},

		/**
		 * @return {String}
		 */
		rendAltImg() {
			const { firstName, name } = this.comment;
			return [firstName, name].join(' ');
		}
	},

	methods: {
		...mapFilters(['forHuman']),

		handPinLabelOrder(status) {
			this.manager.dispatch('setCurrPinHovered', status ? this.comment.pinInfoCode : null);
		},

		async handleDelComment() {
			await this.manager.dispatch('delCommentItem', this.comment.id);
		},

		handleEditComment() {
			console.log('handleEditComment');
		}
	}
};
</script>

<style lang="scss">
li.comment-feed-item {
	position: relative;
	padding-top: 8px;
	padding-bottom: 8px;
	&:last-child {
		margin-bottom: 24px;
	}

	& > .block {
		display: flex;
		position: relative;
		width: 100%;

		.side_ {
			&avatar {
				flex-shrink: 0;
				margin-right: 8px;
				width: 32px;
			}
			&content {
				flex: 1 1 auto;
				min-width: 1px;
				overflow-wrap: break-word;

				.block_content- {
					&header {
						align-items: flex-start;
						display: flex;
						padding: 0;

						& > .header-content {
							flex: 1 1 auto;
							line-height: 22px;
							min-width: 1px;
							display: flex;
							justify-content: space-between;
							align-items: center;
							padding: 0;

							// .header-content-- {
							// 	&left {
							// 	}
							// 	&right {
							// 	}
							// }

							.dropdown {
								z-index: 12;
								left: auto;
								border-radius: 8px;
								height: 24px;
								width: 24px;
								margin: auto;
								opacity: 0;
								transition: all ease-in-out 200ms;

								// button {}
								button {
									padding: 0;
									background-color: rgba(255, 255, 255, 0.6);
									i {
										color: #333;
										font-size: 12px;
										font-weight: 700;
									}
									&:hover {
										background-color: rgba(255, 255, 255, 0.2);
									}
								}
							}

							span {
								&.user-name {
									font-size: 14px;
									line-height: 22px;
								}
								&:not(:last-child) {
									margin-right: 5px;
								}
							}
						}
					}
					&body {
						span.pin-indication {
							display: inline-block;
							border-radius: 50%;
							background-color: aqua;
							cursor: pointer;
							margin-right: 10px;
							width: 24px;
							height: 24px;
							line-height: 24px;
							text-align: center;
							transition: transform ease-in-out 100ms;
							&:hover {
								transform: scale(1.2);
							}
						}
					}
				}
			}
		}
	}
}
.image_comment_list_item_wrapper_component {
	list-style: none;
	padding: 0 15px 0 10px;
	transition: background-color ease-in-out 200ms;
	border-radius: 5px;
	background-color: transparent;

	&:not(:last-child) {
		margin-bottom: 15px;
	}

	&:hover {
		background-color: rgba(153, 153, 153, 0.11);
		& > .block .side_content .block_content-header > .header-content .dropdown {
			opacity: 1 !important;
		}
	}

	.side_ {
		&avatar {
			flex-shrink: 0;
			margin-right: 8px;
			width: 32px;
		}
		&content {
			float: right;
			width: calc(100% - 60px);

			.comment {
				background-color: #ecefef;
				border-radius: 10px;
				padding: 10px;
			}
		}
	}

	span.insert-date {
		text-align: right;
		display: inline-block;
		width: 100%;
		small {
			font-style: italic;
		}
	}
}
</style>
