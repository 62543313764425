var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "modal",
      attrs: {
        "header-class": "header-class-modal-doc-package",
        title: this.FormMSG(10, "Tip of the day"),
        id: "tip-of-the-day-modal",
        size: "xl",
        "modal-class": "tip-modal",
        "hide-header": "",
        "hide-footer": "",
      },
    },
    [
      _c("div", { staticClass: "tip-modal-content" }, [
        _c("strong", [_vm._v(_vm._s(this.tipOfTheDay.categoryName))]),
        _c("p", {
          domProps: { innerHTML: _vm._s(this.tipOfTheDay.description) },
        }),
        _c(
          "div",
          { staticClass: "w-100 py-4" },
          [
            _c(
              "b-button",
              {
                attrs: { variant: "link" },
                on: {
                  click: function ($event) {
                    return _vm.nextTip()
                  },
                },
              },
              [
                _vm._v(
                  "\n\t\t\t\t" +
                    _vm._s(this.FormMSG(12, "Next tip")) +
                    "\n\t\t\t"
                ),
              ]
            ),
            _c(
              "b-button",
              {
                attrs: { variant: "link" },
                on: {
                  click: function ($event) {
                    return _vm.openMyTipOfTheDay()
                  },
                },
              },
              [
                _vm._v(
                  "\n\t\t\t\t" +
                    _vm._s(this.FormMSG(13, "All tips")) +
                    "\n\t\t\t"
                ),
              ]
            ),
            _c(
              "b-button",
              {
                staticClass: "float-right",
                attrs: { variant: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.closeTipOfTheDay()
                  },
                },
              },
              [
                _vm._v(
                  "\n\t\t\t\t" +
                    _vm._s(this.FormMSG(11, "Thank you !")) +
                    "\n\t\t\t"
                ),
              ]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }