<template>
	<!-- Warning on delete images -->
	<b-modal
		header-class="header-class-modal-doc-package"
		header-bg-variant="warning"
		:title="FormMSG(1, 'Please Confirm')"
		class="modal-warning"
		size="sm"
		v-model="value"
		hide-header-close
		no-close-on-backdrop
		no-close-on-esc
		centered
		static
	>
		<div>{{ FormMSG(2, 'Please confirm that you want delete this image') }}<br /></div>
		<template #modal-footer>
			<div class="w-100">
				<b-row class="padding-c-1">
					<b-col cols="12">
						<div class="d-flex flex-row justify-content-center align-items-center w-100" style="gap: 20px">
							<b-button size="sm" variant="outline-danger" class="w-100-px" @click="hideImageDeleteWarning">
								{{ FormMSG(3, 'NO') }}
							</b-button>
							<b-button size="sm" variant="success" class="w-100-px" @click="confirmImageDelete">
								{{ FormMSG(4, 'YES') }}
							</b-button>
						</div>
					</b-col>
				</b-row>
			</div>
		</template>
	</b-modal>
	<!-- Warning on delete images -->
</template>

<script>
import languageMessages from '@/mixins/languageMessages';

export default {
	name: 'DeleteImageModal',
	props: ['value'],
	mixins: [languageMessages],
	methods: {
		hideImageDeleteWarning() {
			this.$emit('delete-image-modal:hide');
		},
		confirmImageDelete() {
			this.$emit('delete-image-modal:confirm');
		}
	}
};
</script>
